.terms-container-privacy {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: calc(85vh - 48px);
  overflow-y: auto;
}

.terms-header-privacy{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 24px 0;
  text-align: center;
}

.header-icon {
  margin-right: 12px;
  padding: 8px;
  border-radius: 50%;
}

.svg-background {
  background-color: #680074;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.terms-content {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 16px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;
  width: 100%;
}

.terms-section h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin: 0 0 12px 0;
  border: none;
}

.terms-section p {
  font-size: 14px;
  line-height: 1.6;
  color: #666;
  margin: 0 0 16px 0;
}

.agree-button {
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  background: #680074;
  border: none;
  color: white;
}

.agree-button:hover {
  background: #4169e1;
}

/* Custom scrollbar */
.terms-content::-webkit-scrollbar {
  width: 4px;
}

.terms-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

.terms-content::-webkit-scrollbar-thumb {
  background: #680074;
  border-radius: 2px;
}

.terms-content::-webkit-scrollbar-thumb:hover {
  background: #680074;
}

@media (max-width: 480px) {
  .terms-header-privacy {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .button-container {
    flex-direction: column;
  }
  
  .agree-button {
    width: 100%;
  }
}

.terms-section {
  background: #fff;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  border: 1px solid #eee;
}

.terms-section ul {
  padding-left: 24px;
  margin: 12px 0;
}

.terms-section li {
  color: #666;
  margin-bottom: 8px;
  line-height: 1.6;
}

/* Add these styles */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.react-datepicker__input-container input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.react-datepicker {
  font-family: inherit;
  border: 1px solid #ddd;
  border-radius: 0.3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.react-datepicker__header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

.react-datepicker__navigation {
  top: 1rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0.3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
