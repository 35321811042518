.historical-appointment-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  font-size: 2.5rem;
  color: #680074;
  margin-bottom: 20px;
  text-align: center;
}

.btn-back {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #680074;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s, transform 0.2s;
}

.btn-back:hover {
  background-color: #520057;
  transform: scale(1.05);
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #666;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #680074;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

.appointment-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.appointment-card {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 1rem;
  transition: transform 0.2s;
}

.appointment-card:hover {
  transform: translateY(-5px);
}

.appointment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appointment-date {
  font-size: 1rem;
  color: #6c757d;
}

.file-section {
  margin-top: 15px;
}

.remark-section {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #f8f9fa;
}

.file-preview-card {
  display: flex;
  align-items: center;
  background: #f8f9fa;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  transition: box-shadow 0.2s;
}

.file-preview-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.file-icon {
  font-size: 1.5rem;
  color: #680074;
  margin-right: 1rem;
}

.file-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-name {
  font-weight: 500;
  color: #212529;
}

.file-date {
  font-size: 0.85rem;
  color: #6c757d;
}

.file-actions {
  display: flex;
  gap: 10px;
}

.btn-preview,
.btn-download {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.btn-preview {
  background-color: #6c757d;
  color: white;
}

.btn-preview:hover {
  background-color: #5a6268;
  transform: scale(1.05);
}

.btn-download {
  background-color: #007bff;
  color: white;
}

.btn-download:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.no-records {
  text-align: center;
  font-size: 1.2rem;
  color: #999;
} 