.st-mar-style-font{
  text-decoration: none;
  color: black;
}
.OvulationTracker{
  text-decoration: none;
}
.background_home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
}

.modal.show ~ .modal-backdrop {
  display: block;
}

.content_wrapper {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem;
}
.picture_fix {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.home_picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home_intro-style {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  z-index: 1;
}
.main_title {
  font-size: clamp(1.5rem, 5vw, 2.5rem);
  color: #333;
  margin-bottom: 1rem;
}
.sub_title {
  font-size: clamp(1rem, 3vw, 1.2rem);
  color: #666;
  margin-bottom: 2rem;
}
.cta_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}
.cta_button {
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
  width: 100%;
}
.cta_button--primary {
  background-color: #007bff;
  color: white;
}
.cta_button--secondary {
  background-color: #6c757d;
  color: white;
}
.cta_button--tertiary {
  background-color: rgb(197, 87, 219);
  color: white;
}
.cta_button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}
.healthcare_check {
  display: flex;
  /* margin-top: 1%; */
  width: 100%;
}

@media (max-width: 480px) {
  .home_intro-style {
    padding: 1.5rem;
  }
}
.navbar_master {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  width: 100%;
  padding: 2.1rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
  position: relative;
}
.nav-menu {
  display: flex;
  align-items: center;
}
.desktop-menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.desktop-menu li {
  margin-left: 1rem;
}
.desktop-menu li a {
  color: #2c3e50;
  text-decoration: none;
  font-size: clamp(0.8rem, 2vw, 1rem);
  cursor: pointer;
}
.mobile-menu {
  display: none;
  list-style-type: none;
  padding: 0;
}
.profile-dropdown {
  position: relative;
}
.dropdown1 {
  position: absolute;
  top: 100%;
  margin-top: 5%;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 3;
  display: none;
}
.dropdown1.show {
  display: block;
}
.dropdown-content1 {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
}
.dropdown-content1 a {
  padding: 0.5rem 1rem;
  color: black;
  text-decoration: none;
}
.dropdown-content1 a:hover {
  background-color: #f1f1f1;
}

.profile-pic-small {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-toggle1 {
  position: relative;
}

.dropdown1 {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 150px;
  z-index: 1000;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown-toggle1:hover .dropdown1 {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-content1 {
  padding: 8px 0;
}

.dropdown-content1 a {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.dropdown-content1 a:hover {
  background-color: #f5f5f5;
}

.dropdown-content1 a svg {
  margin-right: 8px;
  font-size: 16px;
}

.checkbtn{
  /* font-size: 30px; */
  color: black;
  float: right;
  line-height: 80px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
}
.checkbtn img{
  width: 5%;
  float: right;
  
}
#check{
  display: none;
}
@media (max-width: 952px){
  .checkbtn{
    position: absolute;
    right: 20px;
    top: 50px; 
    display: block;
  }
  .navbar_master ul{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 82px;
    left: -100%;
    background-color: rgba(255, 255, 255, 0.95); 
    text-align: center;
    transition: all .5s;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 20px; 
  }
  .navbar_master ul li{
    margin: 15px 0;
  }
  #check:checked ~ ul{
    left: 0;
  }
  .dropdown1 {
    position: static;
    width: 100%;
    box-shadow: none;
    background-color: transparent;
  }
  .dropdown-content1 {
    padding: 10px 0;
  }
  .dropdown-content1 a {
    padding: 10px 0;
    color: #333;
  }
}

@media (min-width: 768px) {
  .cta_container {
    flex-direction: row;
    justify-content: center;
  }

}

@media screen and (max-width: 768px) {
  .navbar_master {
    flex-wrap: wrap;
  }
  .checkbtn {
    display: block;
    order: 1;
  }
  .page_name {
    order: 2;
  }
  .nav-menu {
    order: 3;
    width: 100%;
  }
  .desktop-menu {
    display: none;
  }
  .mobile-menu {
    display: block;
  }
  .dropdown1 {
    position: static;
    width: 100%;
    box-shadow: none;
  }
  .dropdown-content1 {
    padding: 0;
  }
  .dropdown-content1 a {
    padding: 0.5rem 0;
  }
  #check:checked ~ .nav-menu .dropdown1 {
    display: block;
  }
}
.page_name {
  color: #2c3e50;
  font-size: clamp(2.1rem, 4vw, 2.8rem);
  font-weight: bold;
  text-decoration: none;
}
.page_name span{
  color: rgb(197, 87, 219);
}
.navbar_master ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: center;
}
.navbar_master ul li {
  margin-left: 1rem;
}
.navbar_master ul li a {
  color: #2c3e50;
  text-decoration: none;
  font-size: clamp(0.8rem, 2vw, 1rem);
  cursor: pointer;
}
.dropdown-toggle1:hover .dropdown1 {
  display: block;
}
.dropdown-toggle1:focus .dropdown1 {
  display: block;
}
.dropdown-toggle1 .dropdown1 {
  position: absolute;
  top: 12%;
  right: 0;
  z-index: 1;
  display: block;
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.hamburger-icon span {
  display: block;
  height: 3px;
  width: 100%;
  background-color: #333;
  transition: all 0.3s ease-in-out;
}

.check:checked ~ .checkbtn .hamburger-icon span:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.check:checked ~ .checkbtn .hamburger-icon span:nth-child(2) {
  opacity: 0;
}

.check:checked ~ .checkbtn .hamburger-icon span:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

.dropdown-toggle1 {
  position: relative;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #dc3545;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.7rem;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  animation: pulse 1.5s infinite;
}

.notification-indicator {
  margin-left: 8px;
  background-color: #dc3545;
  color: white;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 0.75rem;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.dropdown-content1 a {
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.2s ease; */
}

.dropdown-content1 a:hover {
  background-color: #f5f5f5;
}

.dropdown-content1 a svg {
  margin-right: 8px;
  font-size: 16px;
}

.notification-indicator {
  margin-left: 8px;
  background-color: #dc3545;
  color: white;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 0.75rem;
  animation: pulse 1.5s infinite;
}

.dropdown-content1 a.history-link,
.dropdown-content1 a.records-link {
  color: #520057;
}

.dropdown-content1 a.history-link:hover,
.dropdown-content1 a.records-link:hover {
  background-color: rgba(82, 0, 87, 0.05);
}