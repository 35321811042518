.user-profile-page {
  background-color: #f8f9fa;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  min-height: 100vh;
}

.profile-card, .details-card, .remark-card, .appointment-card {
  background-color: #ffffff;
  border: none;
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.profile-card:hover, .details-card:hover, .remark-card:hover, .appointment-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.profile-image-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #f0f0f0;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  overflow: hidden;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.profile-icon {
  font-size: 120px;
  color: #680074;
}

.edit-profile-pic {
  position: absolute;
  bottom: 25px;
  right: 15px;
  background-color: rgba(104, 0, 116, 0.8);
  color: white;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  z-index: 2;
}

.edit-profile-pic:hover {
  background-color: rgba(104, 0, 116, 0.9);
}

.edit-profile-pic svg {
  font-size: 16px;
}

.detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.detail-icon {
  font-size: 1.5rem;
  color: #680074;
}

.table th {
  font-weight: 600;
  color: #333;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 123, 255, 0.05);
}

.badge {
  font-size: 0.8rem;
  padding: 0.5em 0.75em;
}

.btn-primary {
  background-color: rgb(197, 87, 219);
  border-color: rgb(197, 87, 219);
}

.btn-primary:hover {
  background-color: rgb(197, 87, 219);
  border-color: rgb(197, 87, 219);
}

.modal-content {
  border-radius: 15px;
}

.modal-header {
  background-color: rgb(197, 87, 219);
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.modal-footer {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

@media (max-width: 768px) {
  .profile-card {
    margin-bottom: 2rem;
  }
}

.remark-card .card-body {
  padding: 1.5rem;
}

.remark-content {
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  padding: 1rem;
}

.remark-text {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.remark-icon, .timestamp-icon, .no-remark-icon {
  font-size: 1.2rem;
  margin-right: 0.75rem;
  color: #6c757d;
}

.remark-text p {
  margin: 0;
  flex-grow: 1;
}

.remark-timestamp {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.no-remark {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  padding: 1rem;
}

.no-remark p {
  margin: 0;
  margin-left: 0.75rem;
}

@media (min-width: 768px) {
  .profile-card {
    /* height: calc(100% ); */
    margin-bottom: 2rem;
  }

  .details-card,
  .appointment-card,
  .remark-card {
    margin-bottom: 2rem;
  }
}

.appointment-card .table {
  font-size: 0.9rem;
}

.appointment-card .badge {
  font-size: 0.8rem;
}



.btn-outline-primary {
  border-color: #680074 !important;
  color: #680074;
}

.btn-outline-primary:hover {
  background-color: rgb(197, 87, 219) !important;
  color: white !important;
  border-color: rgb(197, 87, 219) !important;
}

.history-card {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}

.appointment-history-list {
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;
}

.appointment-history-list::-webkit-scrollbar {
  width: 6px;
}

.appointment-history-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.appointment-history-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.appointment-history-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.appointment-history-item {
  background: #fff;
  transition: all 0.3s ease;
  border: 1px solid #e9ecef;
}

.appointment-history-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.1) !important;
}

.current-appointment {
  background: linear-gradient(to right, #f8f9fa, #fff);
}

.status-badge {
  padding: 0.5rem 1rem;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.status-badge.bg-success {
  background-color: #d1e7dd !important;
  color: #0f5132;
}

.status-badge.bg-danger {
  background-color: #f8d7da !important;
  color: #842029;
}

.status-badge.bg-warning {
  background-color: #fff3cd !important;
  color: #664d03;
}

.status-badge.bg-info {
  background-color: #cff4fc !important;
  color: #055160;
}

.detail-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.appointment-type-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f8f9fa;
}

.appointment-count {
  font-size: 0.9rem;
  padding: 5px 10px;
}

.appointment-stats-user {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.stat-card {
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  color: white;
}

.stat-card.completed {
  background-color: #28a745;
}

.stat-card.rejected {
  background-color: #dc3545;
}

.stat-card.remarked {
  background-color: #17a2b8;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
}

.stat-label {
  font-size: 14px;
  opacity: 0.9;
}

@media (max-width: 576px) {
  .stat-value {
    font-size: 1.2rem;
  }
  
  .stat-label {
    font-size: 0.7rem;
  }
  
  .stat-card {
    padding: 0.6rem;
  }
}

.appointment-stats-user {
  margin-bottom: 1.5rem;
}

.stat-card {
  padding: 0.75rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.stat-label {
  font-size: 0.8rem;
  color: #666;
}

.stat-card.completed {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.stat-card.rejected {
  background-color: #ffebee;
  color: #c62828;
}

.stat-card.remarked {
  background-color: #e3f2fd;
  color: #1565c0;
}

.appointment-count {
  font-size: 0.9rem;
  padding: 0.5rem 0.75rem;
}

.appointment-history {
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
}

.table-responsive {
  margin: 0;
  padding: 0;
}

.table {
  margin-bottom: 0;
}

.table th {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  color: #495057;
  font-weight: 600;
}

.table td {
  vertical-align: middle;
  padding: 1rem;
}

.stat-card.pending {
  background-color: #fff3e0;
  color: #e65100;
}

.badge {
  padding: 0.5em 0.75em;
  font-weight: 500;
}

.appointment-stats-user .row {
  margin: 0 -0.5rem;
}

.appointment-stats-user .col-md-3 {
  padding: 0 0.5rem;
}

.stat-card {
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.2s;
}

.stat-card:hover {
  transform: translateY(-2px);
}

.current-appointment {
  border: 2px solid #17a2b8 !important;
  background-color: rgba(23, 162, 184, 0.05);
}

.stat-card.pending {
  background-color: #fff3e0;
  color: #e65100;
}

.badge.bg-info {
  background-color: #17a2b8 !important;
  color: white;
  font-size: 0.75rem;
  padding: 0.25em 0.5em;
}

.history-card {
  transition: transform 0.2s ease-in-out;
}

.history-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
}

.appointment-history-list {
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}

.modal-history-list {
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 10px;
}

.modal-history-list::-webkit-scrollbar {
  width: 8px;
}

.modal-history-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.modal-history-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.modal-history-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modal-dialog {
  margin: 1.75rem auto;
}

.modal-content {
  border: none;
  border-radius: 15px;
}

.modal-header {
  background-color: #680074;
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 1rem 1.5rem;
}

.modal-header .btn-close {
  color: white;
  opacity: 1;
}

.modal-body {
  padding: 1.5rem;
}

.modal-footer {
  padding: 1rem 1.5rem;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.btn-outline-primary {
  color: #680074;
  border-color: #680074;
}

.btn-outline-primary:hover {
  background-color: rgb(197, 87, 219) !important;
  color: white !important;
  border-color: rgb(197, 87, 219) !important;
}

.modal-history-list {
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 10px;
}

.modal-history-list::-webkit-scrollbar {
  width: 8px;
}

.modal-history-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.modal-history-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.modal-history-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.stat-card-modal {
  transition: all 0.3s ease;
}

.stat-card-modal:hover {
  transform: translateY(-5px);
}

.stat-card-modal .stat-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: white;
}

.stat-card-modal.pending {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
}

.stat-card-modal.pending .stat-icon {
  background-color: #ffc107;
}

.stat-card-modal.completed {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

.stat-card-modal.completed .stat-icon {
  background-color: #28a745;
}

.stat-card-modal.rejected {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}

.stat-card-modal.rejected .stat-icon {
  background-color: #dc3545;
}

.stat-card-modal.remarked {
  background-color: #cce5ff;
  border: 1px solid #b8daff;
}

.stat-card-modal.remarked .stat-icon {
  background-color: #007bff;
}

.status-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.status-indicator.pending {
  background-color: #ffc107;
}

.status-indicator.completed {
  background-color: #28a745;
}

.status-indicator.rejected {
  background-color: #dc3545;
}

.status-indicator.remarked {
  background-color: #007bff;
}

.appointment-history-item {
  background-color: white;
  transition: all 0.3s ease;
}

.appointment-history-item:hover {
  transform: translateX(5px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.detail-item {
  display: flex;
  align-items: center;
}

.additional-info {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px;
}

.current-appointment {
  border-left: 4px solid #007bff !important;
}

.folder-container {
  position: relative;
  margin: 20px 0;
  perspective: 1000px;
}

.folder-tab {
  background: linear-gradient(to bottom, #7b0086, #680074);
  height: 45px;
  border-radius: 12px 12px 0 0;
  position: relative;
  margin: 0 50px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  color: white;
  font-weight: 600;
  letter-spacing: 0.5px;
  box-shadow: 0 -3px 6px rgba(0,0,0,0.1);
}

.folder-tab::before,
.folder-tab::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 50px;
  height: 100%;
  background: inherit;
  z-index: -1;
}

.folder-tab::before {
  left: -49px;
  border-radius: 12px 0 0 0;
  transform: skewX(25deg);
  transform-origin: bottom right;
}

.folder-tab::after {
  right: -49px;
  border-radius: 0 12px 0 0;
  transform: skewX(-25deg);
  transform-origin: bottom left;
}

.folder-content {
  background: white;
  border-radius: 0 0 15px 15px;
  box-shadow: 
    0 10px 30px rgba(0,0,0,0.15),
    0 2px 4px rgba(0,0,0,0.12);
  position: relative;
  padding: 25px;
  margin-top: -1px;
  transform-origin: top;
  transition: transform 0.3s ease;
}

/* Folder texture and details */
.folder-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(90deg, 
      rgba(255,255,255,.07) 0px, 
      transparent 1px
    ) 0 0 / 20px 20px,
    linear-gradient(0deg,
      rgba(255,255,255,.07) 0px, 
      transparent 1px
    ) 0 0 / 20px 20px;
  pointer-events: none;
}

.folder-content::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(to right, 
    rgba(104, 0, 116, 0.2),
    transparent
  );
  border-radius: 2px 0 0 2px;
}

.folder-container:hover .folder-content {
  transform: translateY(2px);
}

.folder-tab:hover {
  background: linear-gradient(to bottom, #8b0096, #780084);
}

.folder-tab:hover::before,
.folder-tab:hover::after {
  background: inherit;
}

.folder-container::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 15px;
  right: 15px;
  height: 15px;
  background: linear-gradient(to bottom, 
    rgba(0,0,0,0.08),
    transparent
  );
  filter: blur(4px);
  border-radius: 50%;
  z-index: -1;
}

.folder-body {
  background: linear-gradient(to bottom right,
    #ffffff,
    #f8f9fa
  );
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 20px;
  border: 1px solid rgba(0,0,0,0.05);
}

.folder-label {
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1;
  text-shadow: 0 1px 2px rgba(0,0,0,0.2);
}

.folder-label i {
  font-size: 1.2em;
  opacity: 0.9;
}

@keyframes folderOpen {
  from {
    transform: rotateX(-10deg);
    opacity: 0;
  }
  to {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

.modal.fade.show .folder-container {
  animation: folderOpen 0.4s ease-out forwards;
}

.folder-content {
  background-image: 
    repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 1px,
      rgba(104, 0, 116, 0.03) 1px,
      rgba(104, 0, 116, 0.03) 2px
    );
  background-size: 3px 3px;
}


.btn-view-all {
  background: linear-gradient(145deg, rgb(197, 87, 219), rgb(177, 77, 199)) !important;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px; 
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; 
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: 0.9rem;
  box-shadow: 
    0 4px 15px rgba(197, 87, 219, 0.3),
    0 2px 4px rgba(197, 87, 219, 0.1),
    inset 0 -2px 5px rgba(0,0,0,0.1);
  position: relative;
  overflow: hidden;
}

.btn-view-all::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(255, 255, 255, 0.2) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-view-all:hover {
  background: linear-gradient(145deg, rgb(207, 97, 229), rgb(187, 87, 209)) !important;
  color: white !important;
  transform: translateY(-2px);
  box-shadow: 
    0 6px 20px rgba(197, 87, 219, 0.4),
    0 4px 8px rgba(197, 87, 219, 0.2),
    inset 0 -2px 5px rgba(0,0,0,0.1);
}

.btn-view-all:hover::before {
  transform: translateX(100%);
}

.btn-view-all:active {
  transform: translateY(1px);
  box-shadow: 
    0 2px 10px rgba(197, 87, 219, 0.3),
    0 1px 2px rgba(197, 87, 219, 0.1),
    inset 0 2px 5px rgba(0,0,0,0.1);
}

.btn-view-all .me-2 {
  font-size: 1.1em;
  transition: transform 0.3s ease;
}

.btn-view-all:hover .me-2 {
  transform: scale(1.1);
}
.appointment-count {
  background: linear-gradient(145deg, rgb(197, 87, 219), rgb(177, 77, 199));
  color: white;
  padding: 8px 16px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  border: none;
  box-shadow: 
    0 4px 15px rgba(197, 87, 219, 0.2),
    inset 0 -2px 5px rgba(0,0,0,0.1);
  text-transform: uppercase;
}
.glass-modal .modal-header {
  background: linear-gradient(135deg, rgba(82, 0, 87, 0.95), rgba(122, 0, 138, 0.95));
  padding: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.glass-modal .modal-header .modal-title {
  color: white;
  font-weight: 600;
}

.glass-modal .modal-header .text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}

.glass-modal .btn-close {
  color: white;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.glass-modal .btn-close:hover {
  opacity: 1;
}

.glass-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.glass-appointment-card {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  padding: 1.5rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(82, 0, 87, 0.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.glass-appointment-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.glass-appointment-card .card-status-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #520057, #7a008a);
}

.glass-appointment-card.completed .card-status-indicator {
  background: linear-gradient(90deg, #28a745, #34ce57);
}

.glass-appointment-card.rejected .card-status-indicator {
  background: linear-gradient(90deg, #dc3545, #e4606d);
}

.glass-appointment-card.pending .card-status-indicator {
  background: linear-gradient(90deg, #ffc107, #ffcd39);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.appointment-type h5 {
  color: #520057;
  margin-bottom: 0.25rem;
  font-weight: 600;
}

.current-tag {
  background: rgba(82, 0, 87, 0.1);
  color: #520057;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
  margin-left: 0.5rem;
}

.status-badge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-badge.completed {
  background: rgba(40, 167, 69, 0.1);
  color: #28a745;
}

.status-badge.rejected {
  background: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.status-badge.pending {
  background: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}

.appointment-info {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #666;
}

.message-box {
  background: rgba(82, 0, 87, 0.05);
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
}

.message-box p {
  margin: 0;
  font-size: 0.875rem;
  color: #666;
}

.imported-file-indicator {
  margin-top: 1rem;
  padding: 0.5rem;
  background: rgba(82, 0, 87, 0.05);
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #520057;
  font-size: 0.875rem;
}

.profile-picture-container {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.profile-loading-skeleton {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border-radius: 50%;
}

.profile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.upload-button {
  color: white;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.upload-button:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: scale(1.05);
}

.camera-icon {
  font-size: 24px;
  margin-bottom: 4px;
}

.upload-button span {
  font-size: 14px;
  font-weight: 500;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  animation: spin 1s linear infinite;
}

.pregnancy-info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
}

.info-item {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.info-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.info-item .icon {
    font-size: 1.5rem;
    color: rgb(197, 87, 219);
}

.info-item h6 {
    margin: 0 0 0.5rem 0;
    color: #666;
    font-size: 0.9rem;
}

.info-item p {
    margin: 0;
    color: #333;
    font-weight: 500;
    font-size: 1rem;
}

@media (max-width: 768px) {
    .pregnancy-info-grid {
        grid-template-columns: 1fr;
    }
}

.badge {
  font-size: 0.8em;
}

.badge.bg-danger {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.btn-outline-primary {
  background: linear-gradient(white, white) padding-box,
              linear-gradient(145deg, rgb(197, 87, 219), rgb(177, 77, 199)) border-box;
  border: 1px solid transparent;
  color: rgb(197, 87, 219) !important;
}

.btn-outline-primary:hover {
  background: linear-gradient(145deg, rgb(197, 87, 219), rgb(177, 77, 199)) !important;
  color: white !important;
  border-color: transparent !important;
}

.detail-icon {
  background: linear-gradient(145deg, rgb(197, 87, 219), rgb(177, 77, 199));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* Profile Card Styling */
.profile-card {
  border: none;
  border-radius: 15px;
  background: linear-gradient(145deg, #ffffff, #f5f5f5);
  box-shadow: 0 8px 20px rgba(197, 87, 219, 0.1);
  transition: transform 0.3s ease;
}

.profile-card:hover {
  transform: translateY(-5px);
}

.profile-card .card-body {
  padding: 2rem;
}

.profile-card h3 {
  color: #333;
  font-weight: 600;
  margin-top: 1.5rem;
  font-size: 1.5rem;
}

.profile-card .text-muted {
  color: #666 !important;
  font-size: 0.95rem;
}
.details-card {
  border: none;
  border-radius: 15px;
  background: linear-gradient(145deg, #ffffff, #f5f5f5);
  box-shadow: 0 8px 20px rgba(197, 87, 219, 0.1);
}

.details-card .card-body {
  padding: 2rem;
}

.details-card .card-title {
  color: #333;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
}

.details-card .card-title:after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 50px;
  height: 3px;
  background: linear-gradient(145deg, rgb(197, 87, 219), rgb(177, 77, 199));
  border-radius: 3px;
}

.detail-item {
  background: #fff;
  padding: 1rem;
  border-radius: 10px;
  transition: all 0.3s ease;
  border: 1px solid rgba(197, 87, 219, 0.1);
  height: 100%;
}

.detail-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(197, 87, 219, 0.1);
}

.detail-item .detail-icon {
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(197, 87, 219, 0.1);
  border-radius: 10px;
  padding: 8px;
}

.detail-item h6 {
  font-size: 0.85rem;
  margin-bottom: 0.3rem;
}

.detail-item p {
  color: #333;
  font-size: 1rem;
}

.detail-item input.form-control {
  border: 1px solid rgba(197, 87, 219, 0.2);
  border-radius: 8px;
  padding: 0.5rem;
  transition: all 0.3s ease;
}

.detail-item input.form-control:focus {
  border-color: rgb(197, 87, 219);
  box-shadow: 0 0 0 0.2rem rgba(197, 87, 219, 0.25);
}

.btn-outline-primary {
  border: 2px solid transparent;
  background: linear-gradient(#fff, #fff) padding-box,
              linear-gradient(145deg, rgb(197, 87, 219), rgb(177, 77, 199)) border-box;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
}

.btn-outline-primary:hover {
  background: linear-gradient(145deg, rgb(197, 87, 219), rgb(177, 77, 199)) padding-box,
              linear-gradient(145deg, rgb(197, 87, 219), rgb(177, 77, 199)) border-box;
  color: white !important;
}

.btn-primary {
  background: linear-gradient(145deg, rgb(197, 87, 219), rgb(177, 77, 199));
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background: linear-gradient(145deg, rgb(177, 77, 199), rgb(197, 87, 219));
  transform: translateY(-2px);
}

.btn-secondary {
  background: #f5f5f5;
  border: none;
  color: #666;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.btn-secondary:hover {
  background: #e9e9e9;
  transform: translateY(-2px);
}

.profile-picture-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(197, 87, 219, 0.2);
  border: 3px solid transparent;
  background: linear-gradient(#fff, #fff) padding-box,
              linear-gradient(145deg, rgb(197, 87, 219), rgb(177, 77, 199)) border-box;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.profile-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    transparent,
    rgba(197, 87, 219, 0.8)
  );
  padding: 1rem;
  text-align: center;
}

.upload-button {
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.camera-icon {
  font-size: 1.2rem;
}

.bg-blue {
  background-color: #0066ff !important;
  color: white !important;
}
