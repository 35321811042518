@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f0f2f5;
  color: #333;
}

.articles-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.articles-header {
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  position: relative;
}

.articles-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.header-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  padding: 0 20px;
}

.header-content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.header-content p {
  font-size: 1.2rem;
  line-height: 1.6;
}

.articles-content {
  flex-grow: 1;
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 2rem;
}

.card-item {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-decoration: none;
  color: inherit;
}

.card-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.card-image {
  height: 200px;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;
}

.card-item:hover .card-image {
  transform: scale(1.1);
}

.card-content {
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.category {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.75rem;
  background-color: #e0e0e0;
  color: #333;
}

.reproductive { background-color: #ffd6e0; color: #d63384; }
.family.planning { background-color: #cff4fc; color: #0dcaf0; }
.sex { background-color: #e2e3e5; color: #6c757d; }
.ovulate { background-color: rgb(207, 137, 149); color: rgb(142, 0, 24);}

.card-content h3 {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.4;
}

.arrow {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.card-icon {
  font-size: 1.2rem;
  color: #007bff;
  transition: transform 0.3s ease;
}

.card-item:hover .card-icon {
  transform: translateX(5px);
}

@media (max-width: 1024px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr); 
  }
}

@media (max-width: 768px) {
  .articles-header {
    height: 300px;
  }

  .header-content h1 {
    font-size: 2.5rem;
  }

  .header-content p {
    font-size: 1rem;
  }

  .articles-content {
    padding: 2rem 1rem;
  }

  .card-grid {
    grid-template-columns: 1fr; 
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-item {
  animation: fadeInUp 0.6s ease-out forwards;
  opacity: 0;
}

.card-item:nth-child(1) { animation-delay: 0.1s; }
.card-item:nth-child(2) { animation-delay: 0.2s; }
.card-item:nth-child(3) { animation-delay: 0.3s; }
.card-item:nth-child(4) { animation-delay: 0.4s; }
.card-item:nth-child(5) { animation-delay: 0.5s; }
.card-item:nth-child(6) { animation-delay: 0.6s; }

.edit-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.edit-button {
  background-color: #FB28CD;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.edit-button:hover {
  background-color: #d81b9a;
  transform: translateY(-2px);
}

.edit-button:focus {
  outline: none; 
  box-shadow: 0 0 0 2px #f0aae1; 
}

.articles-modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); 
  backdrop-filter: blur(8px); 
  z-index: 1000; 
}

.articles-modal {
  max-width: 600px; 
  width: 90%; 
  padding: 30px;
  background: linear-gradient(135deg, #ffffff, #f9f9f9); 
  border-radius: 12px; 
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4); 
  display: flex; 
  flex-direction: column;
  align-items: stretch; 
  position: fixed; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
}

.modal-header {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  margin-bottom: 20px; 
  padding: 15px; 
  background-color: #f1f1f1; 
  border-bottom: 2px solid #e0e0e0; 
  border-radius: 12px 12px 0 0; 
}

.modal-title {
  font-size: 1.5rem; 
  font-weight: bold; 
  color: #333; 
}

.close-button {
  background: none; 
  border: none; 
  font-size: 1.5rem; 
  cursor: pointer; 
  color: #888; 
  transition: color 0.3s; 
}

.close-button:hover {
  color: #ff0000; 
}

.edit-article-form {
  display: flex;
  flex-direction: column;
  gap: 20px; 
  padding: 20px; 
  background-color: #f9f9f9; 
  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.edit-article-label {
  font-weight: bold;
  margin-bottom: 8px; 
  color: #333;
  font-size: 1rem; 
}

.edit-article-input {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 15px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease; 
}

.edit-article-input:focus {
  border-color: #007bff; 
  outline: none; 
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); 
}

.edit-article-buttons {
  display: flex;
  justify-content: space-between; 
  margin-top: 20px; 
}

.edit-article-submit,
.edit-article-cancel {
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease; 
  font-size: 1rem; 
}

.edit-article-submit {
  background-color: #28a745; 
  color: #fff;
}

.edit-article-submit:hover {
  background-color: #218838; 
  transform: translateY(-2px); 
}

.edit-article-cancel {
  background-color: #dc3545; 
  color: #fff;
}

.edit-article-cancel:hover {
  background-color: #c82333; 
  transform: translateY(-2px); 
}

.delete-button {
  position: absolute;
  top: 85%;
  bottom: 0;
  background-color: transparent;
  color: #dc3545;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 10px;
  transition: color 0.3s;
  z-index: 2000;
}

.delete-button:hover {
  color: #c82333;
}