@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');
*


.foot_container p{
  font-family: 'Poppins';
  font-style: normal;
  font-optical-sizing: auto;
  color: whitesmoke;
}

.footerers-WL {
  background: #333;
  color: white;
  text-align: center;
  padding: 1rem 0;
  margin-top: 15rem;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footerers-WL{
  margin-top: 20rem;
}
.nav-container-WL{
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 20px;
  z-index: 1000;
  transition: all 0.3s ease;
}
.nav-container-WL .Wlogo{
  display: flex;
  align-items: center;
}
.nav-container-WL .Wlogo span{
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
  margin-left: 10px;
  transition: font-size 0.3s ease;
}
.nav-container-WL .nav-items{
  display: flex;
  align-items: center;
}
.nav-container-WL .nav-items li{
  list-style: none;
  margin: 0 15px;
}
.nav-container-WL .nav-items li a{
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}
.nav-container-WL .nav-items li a:hover{
  color: #a200ff;
}
.nav-container-WL .menu-icon{
  display: none;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .nav-container-WL .menu-icon{
    display: block;
  }
  .nav-container-WL .Wlogo span{ 
    display: block;
    font-size: 2rem;
    /* margin-left: 9%; */
  }
  .nav-container-WL .nav-items{
    position: fixed;
    top: 70px;
    left: -100%;
    width: 100%;
    height: calc(100vh - 70px);
    background: rgba(0, 0, 0, 0.9);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 50px;
    transition: left 0.3s ease;
  }
  .nav-container-WL .nav-items.active{
    left: 0;
  }
  .nav-container-WL .nav-items li{
    margin: 20px 0;
  }
  .nav-container-WL .nav-items li a{
    font-size: 1.2rem;
  }
}

.nav-container-WL .nav-items li{
    list-style: none;
    /* padding: 0 15px; */
  }
  .nav-container-WL .nav-items li a{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    text-shadow: 1px 1px 12px #686868;
  }
  .nav-container-WL .nav-items li a:hover{
    color: rgb(162, 0, 255);
  }

  .nav-container-WL form button:hover{
    background: #e63600;
  }
  .nav-container-WL .menu-icon,
  .nav-container-WL .cancel-icon,
  .nav-container-WL .search-icon{
    width: 40px;
    text-align: center;
    margin: 0 50px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    display: none;
    margin-left: 15px;
  }
  .nav-container-WL .menu-icon span,
  .nav-container-WL .cancel-icon,
  .nav-container-WL .search-icon{
    display: none;
  }
  @media (max-width: 1245px) {
    .nav-container-WL{
      padding: 0 50px;
    }
  }
  @media (max-width: 1140px){
    .nav-container-WL{
      padding: 0px;
    }
    .nav-container-WL .Wlogo{
      flex: 2;
      text-align: center;
    }
    .nav-container-WL .nav-items{
      position: static;
      width: auto;
      left: auto;
      height: auto;
      padding: 0;
      text-align: right;
      background: transparent;
      display: flex;
      transition: none;
    }
    .nav-container-WL .nav-items.active{
      left: 0px;
    }
    .nav-container-WL .nav-items li{
      line-height: normal;
      margin: 0 0 0 1rem;
    }
    .nav-container-WL .nav-items li a{
      font-size: 1rem;
    }
    .nav-container-WL form{
      position: absolute;
      top: 80px;
      right: 50px;
      opacity: 0;
      pointer-events: none;
      transition: top 0.3s ease, opacity 0.1s ease;
    }
    .nav-container-WL form.active{
      top: 95px;
      opacity: 1;
      pointer-events: auto;
    }
    .nav-container-WL form:before{
      position: absolute;
      content: "";
      top: -13px;
      right: 0px;
      width: 0;
      height: 0;
      z-index: -1;
      border: 10px solid transparent;
      border-bottom-color: #DBDBDB;
      margin: -20px 0 0;
    }
    .nav-container-WL form:after{
      position: absolute;
      content: '';
      height: 60px;
      padding: 2px;
      background: #DBDBDB;
      border-radius: 2px;
      min-width: calc(100% + 20px);
      z-index: -2;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .nav-container-WL .menu-icon{
      display: block;
    
    }
    .nav-container-WL .search-icon,
    .nav-container-WL .menu-icon span{
      display: block;

    }
    .nav-container-WL .menu-icon span.hide,
    .nav-container-WL .search-icon.hide{
      display: none;

    } 
    .nav-container-WL  .cancel-icon.show{
      display: block;
    }
  }

  .btn a{
    text-decoration: none;
    background: #e74c3c;
    color: #f2f2f2;
    padding: 5px 8px;
    border-radius: 3px;
    display: inline-flex;
    transition: 0.2s;
  }
  .btn a:hover{
    transform: scale(0.9);
  }
  
  .nav-items {
    overflow: hidden;
    font-family: Arial;
  }
  
  .nav-items a {
    float: left;
    font-size: 16px;
    color: white;
    text-align: center;
    margin-right:1rem;
    /* padding: 14px 16px; */
    text-decoration: none;
  }

  .WPlanitfamitlogo {
    height: auto;
    width: 130px;
    margin-top: -5px;
    transition: width 0.3s ease;
  }

.WelcomemsgHEAD h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 7.5rem;
    color: #ffffff;
    padding: 20rem 0rem 0rem 4rem;
    letter-spacing: 1px;
    line-height: 0.8;
}

.WelcomemsgHEAD h2{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 3.5rem;
  color: #ffffff;
  padding: 0rem 0rem 0rem 4rem;
  line-height: 1em;

}

.WelcomemsgHEAD p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #ffffff;
    text-shadow: 1px 1px 12px #595959;
    padding: 0.5rem 0rem 0rem 4rem;
    text-align: justify;
    line-height: 1.4rem;
    font-size: 1.1rem;
}


.WelcomemsgHEAD h3{
    font-style: normal;
    font-weight: 300;
    font-size: 2.5rem;
    color: #ffff;
    padding: 2rem 0rem 0rem 4rem;
    text-shadow: 1px 1px 8px #5b5b5b;
    font-family: "Caveat", cursive;
    font-optical-sizing: auto;
    font-style: normal;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  z-index: -1;
}

@media (max-width: 1024px) {
  .WelcomemsgHEAD h1 {
    font-size: 6rem;
    padding: 15rem 0rem 0rem 2rem;
  }

  .WelcomemsgHEAD h2 {
    font-size: 3rem;
    padding: 0rem 0rem 0rem 2rem;
  }

  .WelcomemsgHEAD p {
    padding: 0.5rem 2rem 0rem 2rem;
    font-size: 1rem;
  }

  .WelcomemsgHEAD h3 {
    font-size: 2rem;
    padding: 2rem 0rem 0rem 2rem;
  }
}

@media (max-width: 768px) {
  .WelcomemsgHEAD h1 {
    font-size: 4.5rem;
    padding: 12rem 0rem 0rem 1.5rem;
  }

  .WelcomemsgHEAD h2 {
    font-size: 2.5rem;
    padding: 0rem 0rem 0rem 1.5rem;
  }

  .WelcomemsgHEAD p {
    padding: 0.5rem 1.5rem 0rem 1.5rem;
    font-size: 0.9rem;
  }

  .WelcomemsgHEAD h3 {
    font-size: 1.8rem;
    padding: 1.5rem 0rem 0rem 1.5rem;
  }

  .nav-container-WL .Wlogo {
    font-size: 2.5rem;
  }

  .WPlanitfamitlogo {
    width: 100px;
    margin-top: -15px;
  }
}

@media (max-width: 480px) {
  .WelcomemsgHEAD h1 {
    font-size: 3rem;
    padding: 10rem 0rem 0rem 1rem;
  }

  .WelcomemsgHEAD h2 {
    font-size: 1.8rem;
    padding: 0rem 0rem 0rem 1rem;
  }

  .WelcomemsgHEAD p {
    padding: 0.5rem 1rem 0rem 1rem;
    font-size: 0.8rem;
  }

  .WelcomemsgHEAD h3 {
    font-size: 1.5rem;
    padding: 1rem 0rem 0rem 1rem;
  }

  nav .Wlogo {
    font-size: 2rem;
  }

  .WPlanitfamitlogo {
    /* width: 80px; */
    margin-top: -7px;
    margin-left: -1%;
  }

  .nav-container-WL {
    padding: 0 20px;
  }
}

.nav-container-WL {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem;
}

.nav-items {
  display: flex;
  list-style-type: none;
}

.nav-items li {
  margin-left: 1rem;
}

/* Add any additional styles you need for the rest of the component */

.desktop-nav {
  display: flex;
}

.mobile-nav {
  display: none;
  justify-content: center;
  /* position: absolute; */
  /* bottom: 20px; */
  /* left: 0; */
  /* right: 0; */
}

.mobile-nav-button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #8b1a98;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
}

.mobile-nav-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.3;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    display: flex;
  }

  .WelcomemsgHEAD {
    padding-bottom: 80px; /* Add space for mobile nav buttons */
  }
  .login-style-css-WL{
    display: none;
  }
  .register-style-css-WL{
    display: none ;
  }
}

.WelcomemsgHEAD h1, .WelcomemsgHEAD h2, .WelcomemsgHEAD p, .WelcomemsgHEAD h3 {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease forwards;
}

.WelcomemsgHEAD h1 {
  animation-delay: 0.2s;
}

.WelcomemsgHEAD h2 {
  animation-delay: 0.4s;
}

.WelcomemsgHEAD p {
  animation-delay: 0.6s;
}

.WelcomemsgHEAD h3 {
  animation-delay: 0.8s;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-container-WL {
  transition: background-color 0.3s ease;
}



.nav-container-WL .nav-items li a {
  transition: color 0.3s ease, transform 0.2s ease;
}

.nav-container-WL .nav-items li a:hover {
  color: #a200ff;
  transform: scale(1.05);
}

.mobile-nav-button {
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.mobile-nav-button:hover {
  background-color: #6b1475;
  transform: scale(1.05);
}

.WPlanitfamitlogo {
  transition: transform 0.3s ease;
}

.WPlanitfamitlogo:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .mobile-nav {
    display: flex;
    animation: slideUp 0.5s ease;
  }

  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

/* Add these new styles at the end of the file */

/* Page transition effect */
body {
  transition: opacity 0.5s ease-in-out;
}

body.page-exit {
  opacity: 0;
}

/* Button click effect */
.login-style-css-WL a,
.register-style-css-WL a,
.mobile-nav-button {
  position: relative;
  overflow: hidden;
}

.login-style-css-WL a::after,
.register-style-css-WL a::after,
.mobile-nav-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.login-style-css-WL a:focus:not(:active)::after,
.register-style-css-WL a:focus:not(:active)::after,
.mobile-nav-button:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}

/* Improve button hover effect */
.login-style-css-WL a:hover,
.register-style-css-WL a:hover,
.mobile-nav-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.login-style-css-WL a,
.register-style-css-WL a,
.mobile-nav-button {
  transition: all 0.3s ease;
}