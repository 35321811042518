.dashboard-container {
  display: flex;
  background-color: #f4f7fa;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.main-content {
  flex-grow: 1;
  margin-left: 260px;
  padding: 30px;
  transition: all 0.3s ease;
}

.content-wrapper {
  padding: 20px;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}

.page-title-nav {
  font-size: 28px;
  font-weight: 700;
  color: #333;
}

.page-title-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  font-size: 24px;
}

.breadcrumb {
  background-color: transparent;
  padding: 0;
  font-size: 14px;
}

.card {
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  margin-bottom: 30px;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.12);
}

.card-img-holder {
  position: relative;
  overflow: hidden;
}

.card-img-absolute {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.1;
}

.bg-gradient-danger {
  background: linear-gradient(45deg, #ff5e62, #ff9966);
}

.bg-gradient-info {
  background: linear-gradient(45deg, #4e54c8, #8f94fb);
}

.bg-gradient-success {
  background: linear-gradient(45deg, #2ecc71, #1abc9c);
}

.card-body {
  padding: 30px;
}

.font-weight-normal {
  font-weight: 500;
}

.table {
  margin-bottom: 0;
  white-space: nowrap;
}

.table th, .table td {
  padding: 18px;
  vertical-align: middle;
}

.table thead th {
  border-bottom: 2px solid #e0e0e0;
  font-weight: 600;
  text-transform: uppercase;
  color: #333;
  background-color: #f8f9fa;
}

.table tbody tr:hover {
  background-color: #f8f9fa;
}

.btn-sm {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 20px;
  transition: all 0.3s ease;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.3px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.btn-sm:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.15);
}

.btn-sm:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.btn-sm[style*="background-color: #2dce89"]:hover {
  background-color: #26af74 !important;
}

.btn-sm[style*="background-color: #11cdef"]:hover {
  background-color: #0fa6c9 !important;
}

.btn-sm[style*="background-color: #dc3545"]:hover {
  background-color: #c82333 !important;
}

.btn-sm:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(45, 206, 137, 0.2);
}

.pagination {
  margin-top: 30px;
}

.page-link {
  color: #333;
  border: none;
  margin: 0 5px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.page-item.active .page-link {
  background-color: #4e54c8;
  border-color: #4e54c8;
}

.message-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.message-popup-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  max-width: 500px;
  width: 90%;
}

.message-popup h5 {
  margin-bottom: 1rem;
  color: #333;
}

.message-popup p {
  margin-bottom: 1.5rem;
  color: #495057;
}

.message-popup button {
  width: 100%;
}

.btn-link {
  text-decoration: none;
  padding: 0;
}

.btn-link:hover {
  text-decoration: none;
}

.btn-link i {
  transition: color 0.2s;
}

.btn-link:hover i {
  color: #0056b3;
}

/* New styles for better aesthetics */
.card-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.table-actions {
  display: flex;
  gap: 10px;
}

.btn-outline-success, .btn-outline-danger, .btn-outline-info {
  border-width: 2px;
}

.bi {
  font-size: 1.2em;
  vertical-align: middle;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .page-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .breadcrumb {
    margin-top: 15px;
  }

  .table-responsive {
    overflow-x: auto;
  }

  .card-body {
    padding: 20px;
  }

  .table th, .table td {
    padding: 12px;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(78, 84, 200, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(78, 84, 200, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(78, 84, 200, 0);
  }
}

.btn-sm:hover {
  animation: pulse 1.5s infinite;
}

@media (max-width: 991px) {
  .main-content {
    margin-left: 0;
    padding: 20px;
  }

  .page-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .breadcrumb {
    margin-top: 15px;
  }

  .row {
    flex-direction: column;
  }

  .col-md-4 {
    width: 100%;
    margin-bottom: 20px;
  }

  .table-responsive {
    overflow-x: auto;
  }

  .card-body {
    padding: 15px;
  }

  .table th, .table td {
    padding: 10px;
  }
}

@media (max-width: 576px) {
  .main-content {
    padding: 10px;
  }

  .page-title-nav {
    font-size: 24px;
  }

  .card-body {
    padding: 10px;
  }

  .table th, .table td {
    padding: 8px;
    font-size: 12px;
  }

  .btn-sm {
    padding: 0.2rem 0.4rem;
    font-size: 0.7rem;
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.table th {
  border: none;
  padding: 1rem;
}

.table td {
  border: none;
  padding: 1rem;
  vertical-align: middle;
}

.table tbody tr {
  margin-bottom: 0.5rem;
}

.pricing-badge {
  padding: 0.35rem 0.75rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
}

.pricing-badge.selected {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.pricing-badge.no-selection {
  background-color: #f5f5f5;
  color: #757575;
}

.btn-link {
  color: #5e72e4;
  text-decoration: none;
  transition: color 0.2s;
}

.btn-link:hover {
  color: #324cdd;
}

.pagination .page-link {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #8898aa;
  font-weight: 500;
}

.pagination .page-item.active .page-link {
  background-color: #5e72e4;
  color: white;
}

.pagination .page-link:hover {
  background-color: #f6f9fc;
  color: #5e72e4;
}
