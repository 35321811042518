.pregnancy-wheel-container {
    display: flex;
    background-color: #f0f4f8;
    height: 100vh;
    overflow: hidden;
}

.wrapper1-pregnancy {
    flex-grow: 1;
    background-color: #f0f4f8;
    height: 100vh;
    overflow-y: auto;
    padding: 1.5rem;
    font-family: "Montserrat", sans-serif;
    color: #333;
}

.wrapper1-pregnancy h1 {
    color: rgb(197, 87, 219);
    font-size: 2.2rem;
    text-align: center;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper1-pregnancy h1 .icon {
    margin-right: 0.7rem;
    font-size: 2.5rem;
}

.pregnancy-wheel-content {
    display: flex;
    gap: 1.5rem;
    height: calc(100vh - 100px);
}

.input-column, .results-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    overflow-y: auto;
}

.input-section {
    background-color: #f8f9fa;
    padding: 1.5rem;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.input-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.input-section h2 {
    font-size: 1.3rem;
    color: rgb(197, 87, 219);
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.input-section h2 .icon {
    margin-right: 0.7rem;
    font-size: 1.5rem;
}

.input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.input-group {
    display: flex;
    align-items: center;
    margin-bottom: 0.7rem;
}

.input-group label {
    font-size: 1rem;
    margin-right: 0.7rem;
    min-width: 60px;
    color: #555;
}

.input-field {
    padding: 0.5rem;
    width: 5rem;
    border: 2px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.input-field:focus {
    border-color: rgb(197, 87, 219);
    outline: none;
}

.date-display {
    background-color: #e9ecef;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    font-size: 1rem;
    margin-left: 0.7rem;
    color: #555;
}

.slider-container {
    margin-top: 1rem;
}

.slider-container input[type='range'] {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
}

.slider-container input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgb(197, 87, 219);
    cursor: pointer;
    transition: all 0.3s ease;
}

.slider-container input[type='range']::-webkit-slider-thumb:hover {
    transform: scale(1.2);
}

.slider-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    color: #555;
}

.resetBtn {
    display: block;
    margin: 1.5rem auto 0;
    padding: 0.7rem 2rem;
    background-color: rgb(197, 87, 219);
    color: white;
    border: none;
    border-radius: 25px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.resetBtn:hover {
    background-color: rgb(177, 67, 199);
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.date-picker-btn {
    background-color: rgb(197, 87, 219);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.date-picker-btn:hover {
    background-color: rgb(177, 67, 199);
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dates-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
}

.date-item {
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.date-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.date-item h3 {
    font-size: 1rem;
    color: rgb(197, 87, 219);
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 1000;
}

.ReactModal__Content {
    border-radius: 15px !important;
    padding: 0 !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.custom-datepicker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: rgb(197, 87, 219);
    color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.custom-datepicker-header h3 {
    margin: 0;
    font-size: 1.2rem;
}

.close-modal-btn {
    background: none;
    border: none;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.close-modal-btn:hover {
    transform: scale(1.1);
}

.react-datepicker {
    font-family: "Montserrat", sans-serif !important;
    border: none !important;
    width: 100% !important;
}

.react-datepicker__month-container {
    width: 100% !important;
}

.react-datepicker__header {
    background-color: #f8f9fa !important;
    border-bottom: 1px solid #e9ecef !important;
    padding-top: 1rem !important;
}

.react-datepicker__current-month {
    color: rgb(197, 87, 219) !important;
    font-weight: bold !important;
    font-size: 1.1rem !important;
    margin-bottom: 0.5rem !important;
}

.react-datepicker__day-name {
    color: #555 !important;
    font-weight: 600 !important;
}

.react-datepicker__day {
    width: 2.5rem !important;
    line-height: 2.5rem !important;
    margin: 0.2rem !important;
    border-radius: 50% !important;
    transition: all 0.2s ease !important;
}

.react-datepicker__day:hover {
    background-color: #e9ecef !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: rgb(197, 87, 219) !important;
    color: white !important;
    font-weight: bold !important;
}

.react-datepicker__day--today {
    font-weight: bold !important;
    color: rgb(197, 87, 219) !important;
}

.react-datepicker__year-dropdown {
    background-color: #f8f9fa !important;
    border: 1px solid #e9ecef !important;
    border-radius: 8px !important;
}

.react-datepicker__year-option {
    padding: 0.5rem !important;
    transition: background-color 0.2s ease !important;
}

.react-datepicker__year-option:hover {
    background-color: #e9ecef !important;
}

@media (max-width: 768px) {
    .pregnancy-wheel-content {
        flex-direction: column;
    }

    .input-column, .results-column {
        width: 100%;
    }

    .input-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .input-group {
        width: 100%;
        margin-bottom: 0.7rem;
    }

    .date-display {
        margin-left: 0;
        margin-top: 0.5rem;
    }

    .dates-grid {
        grid-template-columns: 1fr;
    }
}

.user-view {
    margin-left: 0 !important;
    width: 100% !important;
}