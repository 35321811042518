.appointment-container-bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../Components/Assets/FamilyPlanning_img2.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  position: relative;
  padding: 4rem 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-x: hidden;
}



.appointment-content {
  position: relative;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 3rem;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  max-width: 1200px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.appointment-header-fil-up {
  text-align: center;
  margin-bottom: 3.5rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid rgba(197, 87, 219, 0.1);
  position: relative;
  
}

.appointment-header-fil-up::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 2px;
  background: linear-gradient(to right, rgb(197, 87, 219), #8b2ba5);
}

.appointment-header-fil-up h1 {
  font-size: 2.75rem;
  font-weight: 800;
  margin-bottom: 1.25rem;
  background: linear-gradient(135deg, rgb(197, 87, 219), #8b2ba5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.5px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.appointment-header-fil-up p {
  color: #64748b;
  font-size: 1.15rem;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.7;
}

.appointment-form {
  padding: 1rem;
}

.form-row {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.form-group {
  flex: 1;
  min-width: 250px;
}

.form-group label {
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.form-control {
  width: 100%;
  padding: 0.875rem 1.25rem;
  border: 2px solid rgba(197, 87, 219, 0.1);
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);
}

.form-control:focus {
  border-color: rgb(197, 87, 219);
  box-shadow: 0 0 0 4px rgba(197, 87, 219, 0.1);
  outline: none;
  background: white;
}
.form-control:hover {
  border-color: rgba(197, 87, 219, 0.5);
  background: white;
}
select.form-control {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='%23333' d='M0 2l4 4 4-4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 8px 8px;
  padding-right: 1.5rem;
}

.input-icon-wrapper {
  position: relative;
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #7f8c8d;
}

.input-icon-wrapper .form-control {
  padding-left: 35px;
}

.submit-btn {
  width: 100%;
  padding: 1.125rem;
  background: linear-gradient(135deg, rgb(197, 87, 219), #8b2ba5);
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 12px rgba(197, 87, 219, 0.2);
}
.submit-btn:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(197, 87, 219, 0.3);
}
.submit-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: linear-gradient(135deg, #a0aec0, #718096);
  box-shadow: none;
}
.submit-btn:hover {
  background-color: rgb(177, 77, 199);
  transform: translateY(-2px);
}

.submit-btn:active {
  transform: translateY(0);
}

.submit-btn:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
  transform: none;
}

.error-message {
  color: #dc3545;
  margin-bottom: 1rem;
}

.booked-times {
  margin-top: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
}

.booked-times h3 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.booked-times-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.booked-times-list li {
  background-color: #e74c3c;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.booked-times-list li:hover {
  background-color: #c0392b;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .appointment-content {
    padding: 2rem;
  }
  
  .appointment-header-fil-up h1 {
    font-size: 2rem;
  }
}

.appointment-form {
  animation: fadeIn 0.5s ease-out;
}
@media (max-width: 768px) {
  .appointment-container-bg {
    padding: 2rem 1rem;
  }
  
  .appointment-content {
    padding: 1.5rem;
    margin: 0 1rem;
  }
  
  .appointment-header-fil-up h1 {
    font-size: 2rem;
  }
  
  .appointment-header-fil-up p {
    font-size: 1rem;
  }
}
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Add smooth transitions */
.appointment-form, .booked-times {
  transition: all 0.3s ease-in-out;
}

/* Add a subtle hover effect to form inputs */
.form-control:hover {
  border-color: #bdc3c7;
}

/* Services Section Container */
.services-section {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 20px;
  padding: 32px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  width: 90%;
  max-width: 1200px;
  max-height: 80vh;
  overflow-y: auto;
  backdrop-filter: blur(10px);
  animation: popIn 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* Add overlay background */
.services-section::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: -1;
  animation: fadeIn 0.3s ease;
}

/* Services Grid */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 24px;
  padding: 16px 4px;
}

/* Animation for pop-in effect */
@keyframes popIn {
  0% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Add close button */
.services-section .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #f1f5f9;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #64748b;
  font-size: 1.2rem;
}

.services-section .close-btn:hover {
  background: #e2e8f0;
  color: #0f172a;
  transform: rotate(90deg);
}

/* Update category filters for the popup */
.category-filters {
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(8px);
  padding: 20px 0;
  margin: -32px -32px 24px -32px;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  border-bottom: 1px solid #e2e8f0;
  padding-left: 32px;
  padding-right: 32px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .services-section {
    width: 95%;
    padding: 24px;
    max-height: 85vh;
  }

  .category-filters {
    margin: -24px -24px 20px -24px;
    padding: 16px;
  }

  .services-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

/* Add exit animation */
@keyframes popOut {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
}

.services-section.closing {
  animation: popOut 0.2s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

/* Category Filters */
.category-filters {
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(8px);
  padding: 16px 0;
  margin: -8px -24px 20px -24px;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  border-bottom: 1px solid #e2e8f0;
  padding-left: 24px;
  padding-right: 24px;
}

.category-btn {
  padding: 10px 18px;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  background: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #64748b;
}

.category-btn:hover {
  background: #f8fafc;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.category-btn.active {
  background-color: rgb(197, 87, 219);
  color: white;
  border-color: #0284c7;
  box-shadow: 0 4px 12px rgba(14, 165, 233, 0.2);
}

/* Service Item */
.service-item {
  border: 1px solid #e2e8f0;
  border-radius: 16px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  overflow: hidden;
}

.service-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(to right,  rgb(197, 87, 219), rgb(132, 60, 146));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.service-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.06);
}

.service-item:hover::before {
  opacity: 1;
}

.service-item.selected {
  border: 2px solid  rgb(197, 87, 219);
  background: linear-gradient(to bottom, #f0f9ff, #ffffff);
}

.service-item.selected::before {
  opacity: 1;
  background-color: rgb(197, 87, 219);
}

/* Service Header */
.service-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.service-header h6 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #0f172a;
  margin: 0;
  line-height: 1.4;
}

.category-tag {
  background: #f1f5f9;
  padding: 6px 10px;
  border-radius: 10px;
  font-size: 1.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Service Pricing */
.service-pricing {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  background: #f8fafc;
  border-radius: 12px;
}

.price-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  font-size: 1rem;
}

.price-detail span {
  color: #64748b;
  font-weight: 500;
}

.price-detail strong {
  font-weight: 700;
  color: #0f172a;
}

.price-detail.philhealth {
  color: rgb(197, 87, 219);
  border-bottom: 1px dashed #e2e8f0;
  padding-bottom: 12px;
}

.price-detail.philhealth strong {
  color: rgb(197, 87, 219);
}

.price-detail.final {
  margin-top: 4px;
  padding: 12px 16px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.price-detail.final span {
  color: #0f172a;
  font-weight: 600;
}

.price-detail.final strong {
  color: rgb(197, 87, 219);
  font-size: 1.25rem;
}

/* Service Selection Indicator */
.service-select {
  margin-top: auto;
  padding-top: 16px;
  border-top: 1px solid #e2e8f0;
  text-align: center;
}

.selected-mark {
  color: rgb(197, 87, 219);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  background: #f0f9ff;
  border-radius: 8px;
}

.selected-mark::before {
  content: '✓';
  font-size: 1.2rem;
  background-color: rgb(197, 87, 219);
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-prompt {
  color: #64748b;
  font-size: 0.95rem;
  padding: 8px;
  background: #f8fafc;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.service-item:hover .select-prompt {
  background: #f0f9ff;
  color: rgb(197, 87, 219);
}

/* Search Box Enhancement */
.search-box input {
  padding: 14px 20px;
  border-radius: 12px;
  border: 2px solid #e2e8f0;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.search-box input:focus {
  border-color: rgb(197, 87, 219);
  box-shadow: 0 0 0 4px rgba(14, 165, 233, 0.1);
}

/* Custom Scrollbar */
.services-section::-webkit-scrollbar {
  width: 8px;
}

.services-section::-webkit-scrollbar-track {
  background: #f8fafc;
  border-radius: 4px;
}

.services-section::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 4px;
  border: 2px solid #f8fafc;
}

.services-section::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .services-section {
    max-height: 80vh;
    padding: 16px;
  }

  .services-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .category-filters {
    padding: 12px;
    margin: -8px -16px 16px -16px;
  }

  .category-btn {
    padding: 8px 14px;
    font-size: 0.9rem;
  }

  .service-pricing {
    padding: 12px;
  }
}

/* Update the selected-services-breakdown styles */
.selected-services-breakdown {
  position: fixed;
  right: 20px;
  top: 80px;
  width: 380px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  padding: 24px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  z-index: 1000;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(0, 0, 0, 0.08);
}

/* Enhanced header styles */
.breakdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 2px solid #f0f2f5;
}

.breakdown-header h5 {
  font-size: 1.2rem;
  color: #1a1f36;
  font-weight: 700;
  margin: 0;
}

.clear-all-btn {
  background: #fee2e2;
  color: #dc2626;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 600;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 6px;
}

.clear-all-btn:hover {
  background: #fecaca;
  transform: translateY(-1px);
}

/* Enhanced table styles */
.table {
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: separate;
  border-spacing: 0;
}

.table thead th {
  background: #f8fafc;
  padding: 12px;
  font-size: 0.85rem;
  font-weight: 600;
  color: #64748b;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-bottom: 2px solid #e2e8f0;
}

.service-row {
  transition: all 0.2s;
  border-bottom: 1px solid #f1f5f9;
}

.service-row:last-child {
  border-bottom: none;
}

.service-row:hover {
  background-color: #f8fafc;
}

.service-name {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: #1e293b;
}

.category-badge {
  background: #f1f5f9;
  color: #475569;
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 0.8rem;
  font-weight: 500;
  white-space: nowrap;
}

.benefit-cell {
  color: #059669;
  font-weight: 600;
}

.remove-service-btn {
  background: none;
  border: none;
  color: #ef4444;
  padding: 6px;
  border-radius: 6px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-service-btn:hover {
  background: #fee2e2;
  transform: scale(1.1);
}

/* Enhanced totals row */
.totals-row {
  background: #f8fafc;
  font-weight: 600;
}

.totals-row td {
  padding: 16px 12px;
  border-top: 2px solid #e2e8f0;
  color: #1e293b;
}

/* Enhanced savings summary */
.savings-summary {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 2px solid #f1f5f9;
}

.savings-card, .final-amount-card {
  padding: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.savings-card {
  background: linear-gradient(145deg, #ecfdf5 0%, #d1fae5 100%);
  border: 1px solid #6ee7b7;
}

.final-amount-card {
  background: linear-gradient(145deg, #1e293b 0%, #0f172a 100%);
  color: white;
  border: 1px solid #334155;
}

.savings-label, .final-amount-label {
  font-size: 0.9rem;
  font-weight: 500;
  opacity: 0.9;
}

.savings-amount {
  color: #059669;
  font-weight: 700;
  font-size: 1.4rem;
}

.final-amount {
  font-weight: 700;
  font-size: 1.4rem;
  color: #fff;
}

/* Mobile responsiveness */
@media (max-width: 992px) {
  .selected-services-breakdown {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    top: auto;
    width: 100%;
    max-height: 80vh;
    border-radius: 20px 20px 0 0;
    padding: 20px;
    transform: translateY(0);
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }

  .breakdown-header {
    position: relative;
  }

  .breakdown-header::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 4px;
    background: #e2e8f0;
    border-radius: 2px;
  }

  .savings-summary {
    grid-template-columns: 1fr;
    margin-bottom: 20px;
  }
}

/* Scrollbar styling */
.selected-services-breakdown::-webkit-scrollbar {
  width: 6px;
}

.selected-services-breakdown::-webkit-scrollbar-track {
  background: #f1f5f9;
  border-radius: 3px;
}

.selected-services-breakdown::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}

.selected-services-breakdown::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

/* Animation for mobile drawer */
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@media (max-width: 992px) {
  .selected-services-breakdown {
    animation: slideUp 0.3s ease-out;
  }
}

/* Add custom scrollbar styling for services-section */
.services-section::-webkit-scrollbar {
  width: 6px;
}

.services-section::-webkit-scrollbar-track {
  background: #f1f5f9;
  border-radius: 3px;
}

.services-section::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}

.services-section::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .services-section {
    max-height: 400px;
  }

  .services-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .service-item {
    max-height: 200px;
  }
}

.service-price-details {
  background: #f8fafc;
  border-radius: 12px;
  padding: 20px;
  margin: 10px 0;
  border: 1px solid #e2e8f0;
}

.service-price-details h6 {
  color: #1e293b;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 1.1rem;
}

.price-breakdown {
  display: grid;
  gap: 12px;
}

.price-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.price-item span {
  color: #64748b;
  font-weight: 500;
}

.price-item strong {
  color: #0f172a;
  font-weight: 600;
}

.price-item.benefit {
  background: #f0fdf4;
  border-color: #86efac;
}

.price-item.benefit strong {
  color: #059669;
}

.price-item.final {
  background: #f1f5f9;
  border-color: #94a3b8;
}

.remove-service-btn-small {
  margin-top: 12px;
  padding: 8px 16px;
  background: #fee2e2;
  color: #dc2626;
  border: none;
  border-radius: 6px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s;
}

.remove-service-btn-small:hover {
  background: #fecaca;
}

.price-item.clickable {
  cursor: pointer;
  transition: all 0.2s ease;
}

.price-item.clickable:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.totals-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  background: white;
  box-shadow: -4px 0 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
}

.totals-popup-content {
  padding: 24px;
  height: 100%;
  overflow-y: auto;
}

.close-totals-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #64748b;
  padding: 4px 8px;
  border-radius: 4px;
}

.close-totals-btn:hover {
  background: #f1f5f9;
  color: #0f172a;
}

.totals-list {
  margin-top: 24px;
}

.total-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #e2e8f0;
}

.total-item span {
  color: #64748b;
  font-weight: 500;
}

.total-item strong {
  color: #0f172a;
  font-weight: 600;
}

.total-item.grand-total {
  margin-top: 16px;
  padding: 16px;
  background: #f8fafc;
  border-radius: 8px;
  border: none;
}

.total-item.grand-total span {
  color: #0f172a;
  font-weight: 600;
}

.total-item.grand-total strong {
  color: rgb(197, 87, 219);
  font-size: 1.2rem;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .totals-popup {
    width: 100%;
  }
}

.price-item.active {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-width: 2px;
}

.price-item.active.benefit {
  border-color: #059669;
  background: #ecfdf5;
}

.price-item.active.final {
  border-color: rgb(197, 87, 219);
  background: #f0f9ff;
}

.price-breakdown {
  transition: all 0.3s ease;
}

.price-item {
  transition: all 0.3s ease;
}

.service-components {
  background: #f8fafc;
  border-radius: 12px;
  padding: 20px;
  margin: 15px 0;
  border: 1px solid #e2e8f0;
  max-height: 400px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.service-components::-webkit-scrollbar {
  width: 4px;
}

.service-components::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.service-components::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 2px;
}

.service-components h6 {
  color: #1e293b;
  margin-bottom: 15px;
  font-weight: 600;
}

.component-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #e2e8f0;
  transition: background-color 0.2s ease;
}

.component-item:hover {
  background-color: #f1f5f9;
}

.component-item:last-child {
  border-bottom: none;
}

.component-info {
  display: flex;
  flex-direction: column;
}

.component-name {
  font-weight: 500;
  color: #334155;
}

.component-price {
  font-weight: 600;
  color: rgb(197, 87, 219);
}

.package-total {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 2px dashed #e2e8f0;
}

.package-total .price-item {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.package-total .price-item.benefit {
  background: #f0fdf4;
  border-color: #86efac;
}

.package-total .price-item.final {
  background: #f0f9ff;
  border-color: #7dd3fc;
}

.package-summary {
  background: #f8fafc;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.package-summary h6 {
  color: #1e293b;
  margin-bottom: 10px;
}

.alert-info {
  background-color: #eff6ff;
  border: 1px solid #bfdbfe;
  border-radius: 6px;
  padding: 10px;
  color: #1e40af;
}

.alert-info small {
  display: block;
  line-height: 1.5;
}

.pricing-type-selector {
  margin-bottom: 20px;
  padding: 15px;
  background: #f8fafc;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.pricing-type-selector label {
  display: block;
  margin-bottom: 10px;
  color: #1e293b;
  font-weight: 600;
}

.pricing-type-selector select {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #cbd5e1;
  background-color: white;
  color: #1e293b;
  font-size: 1rem;
}

.pricing-type-selector select:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.pricing-type-selector option {
  padding: 10px;
}

.service-selection-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
}

.modal-content-selection {
  background: white;
  border-radius: 24px;
  width: 100%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
}

.modal-header-selection {
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}

.modal-header-selection h2 {
  font-size: 1.75rem;
  font-weight: 700;
  background: linear-gradient(135deg, rgb(197, 87, 219), #8b2ba5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.close-modal {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #64748b;
  padding: 4px 8px;
  border-radius: 4px;
}

.close-modal:hover {
  background: #f1f5f9;
  color: #0f172a;
}
.modal-body-selection {
  padding: 2rem;
  flex: 1;
}
.modal-grid {
  display: grid;
  grid-template-rows: 1fr 300px;
  gap: 2rem;
}
.modal-body-selection .component-item {
  padding: 12px;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body-selection .component-item:last-child {
  border-bottom: none;
}

.component-price {
  font-weight: 600;
  color: rgb(197, 87, 219);
}

.btn-link {
  text-decoration: none;
  padding: 0;
  margin: 0;
  font-size: 0.9rem;
}

.btn-link:hover {
  text-decoration: underline;
}

.component-actions {
  display: flex;
  align-items: center;
  gap: 15px;
}

.remove-component-btn {
  background: #fee2e2;
  color: #dc2626;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.2s;
}

.remove-component-btn:hover {
  background: #fecaca;
  transform: translateY(-1px);
}

.component-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #e2e8f0;
  transition: all 0.2s ease;
}

.component-item:hover {
  background-color: #f1f5f9;
}

.component-info {
  flex: 1;
}

.component-price {
  margin-right: 10px;
  color: rgb(197, 87, 219);
  font-weight: 600;
}

.services-selection {
  margin: 2rem 0;
}
.packages-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.package-card {
  background: white;
  border: 2px solid #e2e8f0;
  border-radius: 16px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
}


.package-card:hover {
  border-color: rgb(197, 87, 219);
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(197, 87, 219, 0.1);
}

.package-card.selected {
  border-color: rgb(197, 87, 219);
  background: rgba(197, 87, 219, 0.05);
}

.package-card.selected::after {
  content: '✓';
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgb(197, 87, 219);
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
}
.package-header {
  margin-bottom: 1.5rem;
}

.package-header h3 {
  font-size: 1.25rem;
  font-weight: 700;
  color: #1e293b;
  margin-bottom: 0.5rem;
}

.package-price {
  font-size: 1.5rem;
  font-weight: 700;
  color: rgb(197, 87, 219);
}

.package-description {
  color: #64748b;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.package-preview {
  flex: 1;
  overflow-y: auto;
  max-height: 200px;
  scrollbar-width: thin;
  margin: 0.75rem 0;
}

.package-preview::-webkit-scrollbar {
  width: 4px;
}

.package-preview::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.package-preview::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 2px;
}

.package-preview li {
  padding: 0.5rem;
  font-size: 0.85rem;
}

.select-package-btn {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 8px;
  background: #3b82f6;
  color: white;
  font-weight: 600;
  transition: all 0.2s ease;
  cursor: pointer;
}

.select-package-btn:hover {
  background: rgb(197, 87, 219);
}

.select-package-btn.selected {
  background: #059669;
}

.package-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(to right, rgb(197, 87, 219), rgb(147, 67, 163));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.package-card:hover::before,
.package-card.selected::before {
  opacity: 1;
}

.package-card.hovered {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1200px) {
  .packages-grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 1rem;
  }
}

@media (max-width: 768px) {
  .packages-grid {
    grid-template-columns: 1fr;
  }
  
  .package-card {
    max-width: 100%;
  }
}

/* Animation for package selection */
@keyframes selectPackage {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.package-card.selected {
  animation: selectPackage 0.3s ease;
}

.service-breakdown-container {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin: 1.5rem 0;
  border: 1px solid #e2e8f0;
  overflow: hidden;
}

.breakdown-header {
  background: #f8fafc;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.breakdown-header h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}


.components-table {
  padding: 1rem;
}

.table-header {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 0.75rem 1rem;
  background: #f1f5f9;
  border-radius: 6px;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #475569;
  font-size: 0.9rem;
}

.component-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 0.75rem 1rem;
  align-items: center;
  border-bottom: 1px solid #e2e8f0;
  transition: background-color 0.2s;
}

.component-row:hover {
  background-color: #f8fafc;
}

.component-row:last-child {
  border-bottom: none;
}

.service-name {
  color: #334155;
  font-weight: 500;
}

.price-column {
  text-align: right;
  padding-right: 1rem;
}

.price-amount {
  color: rgb(197, 87, 219);
  font-weight: 600;
  font-size: 1rem;
}

.remove-component-btn {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background: #fee2e2;
  color: #dc2626;
  border: none;
  padding: 0.4rem 0.75rem;
  border-radius: 4px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.2s;
}

.remove-component-btn:hover {
  background: #fecaca;
  transform: translateY(-1px);
}

.remove-icon {
  font-size: 1.1rem;
  line-height: 1;
}

.package-summary {
  background: #f8fafc;
  padding: 1.25rem;
  margin-top: 1rem;
  border-radius: 8px;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  color: #64748b;
}

.total-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  margin-top: 0.5rem;
  border-top: 2px dashed #e2e8f0;
}

.total-label {
  font-weight: 600;
  color: #1e293b;
}

.total-amount {
  font-size: 1.25rem;
  font-weight: 700;
  color: rgb(197, 87, 219);
}

.breakdown-footer {
  padding: 1rem;
  background: #f8fafc;
  border-top: 1px solid #e2e8f0;
  display: flex;
  justify-content: flex-end;
}

.remove-package-btn {
  background: #dc2626;
  color: white;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.remove-package-btn:hover {
  background: #b91c1c;
  transform: translateY(-1px);
}

@media (max-width: 640px) {
  .table-header, .component-row {
    grid-template-columns: 1fr 1fr;
  }
  
  .action-header {
    display: none;
  }
  
  .action-column {
    grid-column: 1 / -1;
    margin-top: 0.5rem;
  }
  
  .remove-component-btn {
    width: 100%;
    justify-content: center;
  }
}

.service-selection-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.service-selection-modal .modal-content-selection {
  background: white;
  border-radius: 16px;
  width: 95%;
  max-width: 1200px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;

}

@keyframes modalSlideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal-header-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 2px solid #f1f5f9;
}

.modal-header-selection h2 {
  font-size: 1.8rem;
  color: #0f172a;
  font-weight: 700;
  margin: 0;
}

.close-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: #f1f5f9;
  color: #64748b;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.close-btn:hover {
  background: #e2e8f0;
  color: #1e293b;
  transform: rotate(90deg);
}

.modal-tabs-selection {
  padding: 1rem 2rem;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  gap: 1rem;
  position: sticky;
  top: 80px;
  background: white;
  z-index: 9;
}
.tab-btn {
  padding: 0.75rem 1.5rem;
  border: 2px solid transparent;
  background: #f8fafc;
  color: #64748b;
  font-weight: 600;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s;
}

.tab-btn:hover {
  background: #f1f5f9;
  color: rgb(197, 87, 219);
}

.tab-btn.active {
  background: rgb(197, 87, 219);
  color: white;
}

/* Enhanced Package Cards */
.packages-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 24px;
  padding: 8px 4px;
}

.package-card {
  background: white;
  border-radius: 16px;
  padding: 24px;
  border: 2px solid #e2e8f0;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.package-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-color: rgb(197, 87, 219);
  opacity: 0;
  transition: opacity 0.3s;
}

.package-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  /* background-color: rgb(197, 87, 219); */
}

.package-card:hover::before {
  opacity: 1;
}

.package-card.selected {
  background-color: rgb(197, 87, 219);
  background: linear-gradient(to bottom, #f0f9ff, white);
}

.package-card.selected::before {
  opacity: 1;
}

.package-header {
  margin-bottom: 16px;
}

.package-header h3 {
  font-size: 1.2rem;
  color: #0f172a;
  margin-bottom: 8px;
  line-height: 1.4;
}

.package-price {
  font-size: 1.5rem;
  font-weight: 700;
  color: rgb(197, 87, 219);
}

.package-description {
  color: #64748b;
  font-size: 0.95rem;
  margin-bottom: 20px;
  line-height: 1.6;
}

.package-components {
  background: #f8fafc;
  border-radius: 12px;
  padding: 1.25rem;
}

.package-components h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 1rem;
}

.package-components ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.package-components li {
  padding: 0.75rem 0;
  border-bottom: 1px solid #e2e8f0;
  color: #64748b;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.package-components li:last-child {
  border-bottom: none;
}

.selected-services-sidebar {
  background: #f8fafc;
  border-radius: 16px;
  padding: 1.5rem;
  position: sticky;
  top: 160px;
  max-height: calc(90vh - 160px);
  overflow-y: auto;
}
.selected-services-sidebar h3 {
  font-size: 1.25rem;
  font-weight: 700;
  color: #1e293b;
  margin-bottom: 1.5rem;
}

.service-breakdown-container {
  background: white;
  border-radius: 12px;
  padding: 1.25rem;
  margin-bottom: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.breakdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}



/* Component Table Styles */
.components-table {
  padding: 16px;
}

.table-header {
  background: #f1f5f9;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 8px;
  font-weight: 600;
  color: #475569;
}

.component-row {
  padding: 12px;
  border-bottom: 1px solid #f1f5f9;
  transition: background-color 0.2s;
}

.component-row:hover {
  background: #f8fafc;
}

.remove-component-btn {
  background: #fee2e2;
  color: #dc2626;
  border: none;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 4px;
}

.remove-component-btn:hover {
  background: #fecaca;
  transform: translateY(-1px);
}

/* Package Summary */
.package-summary {
  background: #f8fafc;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  color: #64748b;
}

.total-row {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 2px dashed #e2e8f0;
  font-weight: 600;
}

.total-amount {
  color: rgb(197, 87, 219);
  font-size: 1.2rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .modal-content-selection {
    margin: 0.5rem;
    max-height: 95vh;
  }
  
  .modal-header-selection,
  .modal-tabs-selection,
  .modal-body-selection,
  .modal-footer-selection {
    padding: 1rem;
  }
  
  .packages-grid {
    grid-template-columns: 1fr;
  }

  .modal-tabs-selection {
    top: 64px;
  }

  .tab-btn {
    white-space: nowrap;
    padding: 10px 16px;
  }
}

.modal-footer-selection {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  z-index: 10;
}

.done-btn {
  padding: 0.75rem 2rem;
  background: rgb(197, 87, 219);
  color: white;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
}


.done-btn:hover {
  background: #8b2ba5;
  transform: translateY(-2px);
}

.selected-services-summary {
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  background-color: #f8f9fa;
}

.selected-services-list {
  margin-top: 0.5rem;
}

.selected-service-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #dee2e6;
}

.service-info {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-right: 1rem;
}

.remove-service-btn {
  background: none;
  border: none;
  color: #dc3545;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0 0.5rem;
}

.remove-service-btn:hover {
  color: #c82333;
}

.total-amount {
  margin-top: 1rem;
  padding-top: 0.5rem;
  border-top: 2px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
}
@media (max-width: 1024px) {
  .modal-grid {
    grid-template-columns: 1fr;
  }
  
  .selected-services-sidebar {
    position: static;
    max-height: none;
  }
}
/* Select Services Button Styling */
.select-services-btn {
  width: 100%;
  padding: 12px 20px;
  background-color: rgb(197, 87, 219);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.select-services-btn:hover {
  background-color: rgb(197, 87, 219);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.select-services-btn:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.select-services-btn:disabled {
  background: #cccccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Add an icon to the button */
.select-services-btn svg {
  width: 20px;
  height: 20px;
}
.individual-services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 2rem;
  padding: 1.5rem;
}

.service-card {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
}

.service-card.selected {
  border: 2px solid rgb(197, 87, 219);
  background: linear-gradient(to bottom right, #ffffff, #fdf4ff);
}

.service-card-header {
  padding: 1.5rem;
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  background: linear-gradient(135deg, #fcf6ff 0%, #ffffff 100%);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.service-title {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
  padding-right: 1rem;
}

.service-icon {
  color: rgb(197, 87, 219);
  font-size: 1.5rem;
  background: rgba(197, 87, 219, 0.1);
  padding: 0.75rem;
  border-radius: 12px;
  transition: all 0.3s ease;
}
.service-card:hover .service-icon {
  transform: scale(1.1);
  background: rgb(197, 87, 219);
  color: white;
}

.service-card-header h3 {
  color: #1a1a1a;
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.4;
}

.service-card-body {
  padding: 1.5rem;
  flex-grow: 1;
}
.service-card-body p {
  color: #4b5563;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}
.service-features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.feature-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #4b5563;
}
.feature-item i {
  color: rgb(197, 87, 219);
  font-size: 1.1rem;
}
   
.service-price {
  padding: 1.5rem;
  background: #f8fafc;
  border-top: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price-info {
  display: flex;
  flex-direction: column;
}
.price-label {
  color: #6b7280;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.price-amount {
  color: rgb(197, 87, 219);
  font-size: 1.5rem;
  font-weight: 700;
}
.pricing-type-badge {
  background: rgba(197, 87, 219, 0.1);
  color: rgb(197, 87, 219);
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  font-size: 0.75rem;
  font-weight: 500;
}
/* .pricing-type-badge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #f3f4f6;
  padding: 0.6rem 1rem;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #4b5563;
  transition: all 0.3s ease;
  white-space: nowrap;
} */
/* .pricing-type-badge i {
  color: rgb(197, 87, 219);
  font-size: 0.9rem;
} */

/* .service-card:hover .pricing-type-badge {
  background: rgb(197, 87, 219);
  color: white;
} */
/* .service-card:hover .pricing-type-badge i {
  color: white;
} */

.select-service-btn {
  background: rgb(197, 87, 219);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.select-service-btn:hover {
  background: rgb(177, 77, 199);
  transform: scale(1.05);
}

.service-card.selected .select-service-btn {
  background: #10b981;
}

@media (max-width: 768px) {
  .individual-services-grid {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
  
  .service-card-header {
    padding: 1.25rem;
    flex-direction: column;
    gap: 1rem;
  }
  .service-card-body,
  .service-price {
    padding: 1.25rem;
  }
  .service-title {
    padding-right: 0;
  }

  .service-card-header h3 {
    font-size: 1.25rem;
    padding-right: 80px;
  }
  
  /* .pricing-type-badge {
    align-self: flex-start;
  } */
}
