.appointment-history-container {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  background-color: #f8f9fa;
  min-height: calc(100vh - 64px);
}

.history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.header-title {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.header-title h2 {
  font-size: 1.8rem;
  color: #2c3e50;
  margin: 0;
}

.total-count {
  background: rgba(197, 87, 219, 0.1);
  padding: 0.4rem 1rem;
  border-radius: 20px;
  color: rgb(197, 87, 219);
  font-weight: 500;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.controls-section {
  background: white;
  padding: 1rem;
  border-radius: 12px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.filter-section {
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
}

.filter-btn {
  padding: 0.6rem 1.2rem;
  border: none;
  border-radius: 8px;
  background: #f0f2f5;
  color: #64748b;
  font-weight: 500;
  transition: all 0.2s ease;
}

.filter-btn.active {
  background: rgb(197, 87, 219);
  color: white;
}

.filter-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.sort-btn {
  padding: 0.6rem 1.2rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  color: #64748b;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
  transition: all 0.2s ease;
}

.appointments-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 1.5rem;
}

.appointment-card {
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
}

.appointment-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.card-header {
  padding: 1.5rem;
  border-bottom: 1px solid #edf2f7;
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  position: relative;
}

.card-header h3 {
  font-size: 1.25rem;
  color: #2d3748;
  font-weight: 600;
  margin-bottom: 0.75rem;
  line-height: 1.4;
}

.status-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  transition: all 0.2s ease;
}

.appointment-card.pending .status-badge { 
  background: #fff8e1; 
  color: #ed8936;
  border: 1px solid rgba(237, 137, 54, 0.2);
}

.appointment-card.approved .status-badge { 
  background: #e6fffa; 
  color: #38b2ac;
  border: 1px solid rgba(56, 178, 172, 0.2);
}

.appointment-card.completed .status-badge { 
  background: #ebf8ff; 
  color: #4299e1;
  border: 1px solid rgba(66, 153, 225, 0.2);
}

.appointment-card.rejected .status-badge { 
  background: #fff5f5; 
  color: #f56565;
  border: 1px solid rgba(245, 101, 101, 0.2);
}

.card-body {
  padding: 1.5rem;
}

.appointment-info {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  margin-bottom: 1.25rem;
  padding: 1rem;
  background: #f7fafc;
  border-radius: 12px;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #4a5568;
  font-size: 0.95rem;
}

.info-item svg {
  color: rgb(197, 87, 219);
  font-size: 1.1rem;
}

.message-section, .remark-section {
  margin-top: 1.25rem;
  padding: 1rem;
  background: #f7fafc;
  border-radius: 12px;
  border-left: 4px solid rgb(197, 87, 219);
}

.message-section svg, .remark-section svg {
  color: rgb(197, 87, 219);
  margin-right: 0.75rem;
  font-size: 1.1rem;
}

.message-section p, .remark-section p {
  color: #4a5568;
  margin: 0.5rem 0 0;
  line-height: 1.6;
}

.imported-file-notification {
  margin-top: 1.25rem;
  padding: 1rem;
  background: rgba(197, 87, 219, 0.08);
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px dashed rgba(197, 87, 219, 0.3);
}

.imported-file-notification .file-icon {
  color: rgb(197, 87, 219);
  font-size: 1.2rem;
}

.view-file-btn {
  color: rgb(197, 87, 219);
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  padding: 0.4rem 0.8rem;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.view-file-btn:hover {
  background: rgba(197, 87, 219, 0.1);
  color: rgb(177, 77, 199);
}

.appointment-card:hover .status-badge {
  transform: scale(1.02);
}

.appointment-card.current-appointment {
  border: 2px solid rgb(197, 87, 219);
}

@media (max-width: 768px) {
  .appointment-info {
    flex-direction: column;
    gap: 1rem;
  }
  
  .card-header {
    padding: 1.25rem;
  }
  
  .card-body {
    padding: 1.25rem;
  }
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.6rem 1.2rem;
  border: none;
  border-radius: 8px;
  background: #f0f2f5;
  color: #64748b;
  transition: all 0.2s ease;
}

.back-button:hover {
  background: #e2e8f0;
}

.historical-btn {
  background: rgb(197, 87, 219);
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 8px;
  color: white;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  white-space: nowrap;
}

.historical-btn:hover {
  background: rgb(177, 77, 199);
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(197, 87, 219, 0.2);
}

.historical-btn svg {
  font-size: 1rem;
}

.loading-spinner-small {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.data-loading-indicator {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background: rgba(0,0,0,0.8);
  color: white;
  padding: 0.8rem 1.2rem;
  border-radius: 8px;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.spinner-border.text-primary {
  color: rgb(197, 87, 219) !important;
}

.appointment-details {
  padding: 15px;
}

.detail-row {
  background: #f8fafc;
  border-radius: 12px;
  padding: 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease;
}

.detail-row.header-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(145deg, rgba(197, 87, 219, 0.05), rgba(177, 77, 199, 0.05));
}

.detail-row.datetime-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  background: linear-gradient(145deg, #ffffff, #f8fafc);
}

.detail-item-history {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.detail-item-history.name-status {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-item-history.name-section {
  flex: 1;
}

.detail-item-history.status-section {
  flex-shrink: 0;
}

.detail-item-history strong {
  color: #4a5568;
  font-size: 0.95rem;
  min-width: 80px;
}

.detail-item-history span.name-value {
  color: rgb(197, 87, 219);
  font-weight: 600;
  font-size: 1.1rem;
}

.detail-item-history.datetime-item {
  padding: 0.5rem;
  background: white;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.detail-item-history.datetime-item strong {
  color: #4a5568;
  min-width: 60px;
  font-size: 0.9rem;
}

.detail-item-history.datetime-item span {
  color: #2d3748;
  font-weight: 500;
  font-size: 1rem;
}

.appt-history-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  overflow-y: auto;
}

.appt-history-modal.show {
  display: block;
  animation: apptHistoryModalFadeIn 0.3s ease;
}

@keyframes apptHistoryModalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.appt-history-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(6px);
  z-index: 1051;
}

.appt-history-modal-content {
  position: relative;
  margin: 2rem auto;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.25);
  width: 90%;
  max-width: 700px;
  z-index: 1052;
  overflow: hidden;
  border: 1px solid rgba(197, 87, 219, 0.1);
}

.appt-history-modal-header {
  background: linear-gradient(135deg, rgb(197, 87, 219), rgb(177, 77, 199));
  padding: 1.75rem 2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
}

.appt-history-modal-header h3 {
  color: white;
  font-size: 1.75rem;
  font-weight: 600;
  margin: 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px;
}

.appt-history-modal-close {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.15);
  border: none;
  color: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.appt-history-modal-close:hover {
  background: rgba(255, 255, 255, 0.25);
  transform: translateY(-50%) rotate(90deg);
}

.appt-history-modal-body {
  padding: 2rem;
  max-height: calc(90vh - 120px);
  overflow-y: auto;
  background: linear-gradient(to bottom, #ffffff, #f8fafc);
}

.appt-history-modal-body::-webkit-scrollbar {
  width: 10px;
}

.appt-history-modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.appt-history-modal-body::-webkit-scrollbar-thumb {
  background: rgba(197, 87, 219, 0.4);
  border-radius: 5px;
  border: 2px solid #f1f1f1;
}

.appt-history-modal-body::-webkit-scrollbar-thumb:hover {
  background: rgba(197, 87, 219, 0.6);
}

.appt-history-detail-row {
  background: white;
  border-radius: 15px;
  padding: 1.5rem;
  margin-bottom: 1.25rem;
  border: 1px solid rgba(197, 87, 219, 0.1);
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
}

.appt-history-detail-row:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(197, 87, 219, 0.1);
}

@media (max-width: 768px) {
  .appt-history-modal-content {
    margin: 1rem;
    width: calc(100% - 2rem);
  }

  .appt-history-modal-header {
    padding: 1.5rem;
  }

  .appt-history-modal-body {
    padding: 1.5rem;
  }
}

.services-list {
  width: 100%;
}

.services-list strong {
  display: block;
  color: #2c3e50;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  /* padding-bottom: 0.5rem; */
  /* border-bottom: 2px solid rgba(197, 87, 219, 0.1); */
}

.services-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.service-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 1rem 1.25rem;
  border-radius: 12px;
  border: 1px solid rgba(197, 87, 219, 0.1);
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
}

.service-item:hover {
  transform: translateX(4px);
  border-left: 3px solid rgb(197, 87, 219);
  background: linear-gradient(to right, rgba(197, 87, 219, 0.02), white);
}

.service-name {
  color: #2c3e50;
  font-weight: 500;
  font-size: 1rem;
  flex: 1;
}

.service-prices {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.price-item {
  background: rgba(197, 87, 219, 0.05);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: 1px solid rgba(197, 87, 219, 0.1);
}

.price-value {
  color: rgb(197, 87, 219);
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .service-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    padding: 1rem;
  }

  .service-name {
    font-size: 0.95rem;
  }

  .service-prices {
    width: 100%;
    justify-content: flex-end;
  }

  .price-item {
    padding: 0.4rem 0.8rem;
  }

  .price-value {
    font-size: 0.95rem;
  }
}

/* Update the datetime row styles */
.datetime-info-row {
  display: flex;
  align-items: center;
  gap: 2rem;
  background: white;
  padding: 1rem 1.25rem;
  border-radius: 12px;
  border: 1px solid rgba(197, 87, 219, 0.1);
}

.datetime-info-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.datetime-info-item strong {
  color: #4a5568;
  font-size: 0.95rem;
  min-width: auto;
}

.datetime-info-item span {
  color: #2d3748;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.4rem 0.8rem;
  background: rgba(197, 87, 219, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(197, 87, 219, 0.1);
}

.datetime-info-item svg {
  color: rgb(197, 87, 219);
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .datetime-info-row {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .datetime-info-item {
    width: 100%;
  }
}

/* Imported file section styles */
.imported-file-section {
  width: 100%;
}

.imported-file-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.imported-file-header strong {
  color: #2c3e50;
  font-size: 0.95rem;
}

.imported-file-header .file-icon {
  color: rgb(197, 87, 219);
  font-size: 1.1rem;
}

.imported-file-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(197, 87, 219, 0.05);
  padding: 1rem 1.25rem;
  border-radius: 12px;
  border: 1px solid rgba(197, 87, 219, 0.1);
}

.imported-file-content span {
  color: #4a5568;
  font-size: 0.95rem;
}

.view-historical-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.6rem 1.2rem;
  background: rgb(197, 87, 219);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.view-historical-btn:hover {
  background: rgb(177, 77, 199);
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(197, 87, 219, 0.2);
}

.view-historical-btn svg {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .imported-file-content {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    padding: 1rem;
  }

  .view-historical-btn {
    width: 100%;
    justify-content: center;
  }
}

/* Add responsive styles for smaller screens */
@media (max-width: 768px) {
  .appointment-history-container {
    padding: 1rem; /* Reduce padding on smaller screens */
  }

  .history-header {
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .header-title {
    flex-direction: column; /* Stack title and total count */
    align-items: flex-start; /* Align items to the start */
  }

  .controls-section {
    flex-direction: column; /* Stack controls vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .filter-section {
    flex-wrap: wrap; /* Allow filters to wrap */
  }

  .appointments-grid {
    grid-template-columns: 1fr; /* Single column layout */
  }

  .appointment-card {
    margin: 0.5rem 0; /* Add margin between cards */
  }

  .appt-history-modal-content {
    width: calc(100% - 2rem); /* Full width with margin */
    margin: 1rem; /* Add margin */
  }

  .appt-history-modal-body {
    padding: 1rem; /* Reduce padding */
  }

  .datetime-info-row {
    flex-direction: column; /* Stack datetime info vertically */
  }

  .datetime-info-item {
    width: 100%; /* Full width for datetime items */
  }

  .imported-file-content {
    flex-direction: column; /* Stack imported file content */
    text-align: center; /* Center text */
  }
}

.enhanced-design {
    border: 1px solid #ccc; /* Add a border */
    border-radius: 8px; /* Rounded corners */
    padding: 16px; /* Padding inside the card */
    margin: 10px; /* Margin between cards */
    transition: transform 0.2s; /* Smooth hover effect */
}

.enhanced-design:hover {
    transform: scale(1.02); /* Slightly enlarge on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
} 