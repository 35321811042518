@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  height:  100vh;
  background: url(./Components/Assets/landing_page_bkg.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
