.user-profile-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .user-profile-popup-content {
    background-color: white;
    border-radius: 8px;
    width: 90%;
    max-width: 1200px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    padding: 20px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1;
  }
  
  .profile-image-container {
    position: relative;
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }
  
  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .profile-icon {
    color: #007bff;
  }
  
  .detail-icon {
    font-size: 1.5rem;
    color: #007bff;
  }
  
  .card {
    border: none;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  
  .card-title {
    color: #007bff;
    font-weight: bold;
  }
  
  .table {
    margin-bottom: 0;
  }
  
  .badge {
    font-size: 0.8rem;
    padding: 0.4em 0.6em;
  }
  
  