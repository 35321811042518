@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.ot-page-container {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  background: white;
}

.ot-background-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.ot-left-bg {
  position: absolute;
  left: -5%;
  top: 15%;
  width: 25%;
  opacity: 0.6;
  animation: float-left 6s ease-in-out infinite;
}

.ot-right-bg {
  position: absolute;
  right: -5%;
  top: 45%;
  width: 25%;
  opacity: 0.6;
  animation: float-right 8s ease-in-out infinite;
}

.ot-left-bg img,
.ot-right-bg img {
  width: 100%;
  height: auto;
  filter: drop-shadow(0 10px 15px rgba(0, 0, 0, 0.1));
}

@keyframes float-left {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes float-right {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-25px); }
}

.ot-container {
  position: relative;
  z-index: 1;
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 2rem;
}

.ot-tracker-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.ot-main-title,
.ot-subtitle {
  position: relative;
  z-index: 1;
}

.ot-main-title {
  color: #FB28CD;
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0.5rem;
}

.ot-subtitle {
  text-align: center;
  color: #666;
  margin-bottom: 3rem;
}

.ot-date-card,
.ot-menstruation-details,
.ot-health-indicators,
.ot-measurements,
.ot-bmi-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 
    0 10px 20px rgba(0, 0, 0, 0.05),
    inset 0 -2px 6px rgba(251, 40, 205, 0.05);
  transition: all 0.3s ease;
  border: 1px solid rgba(251, 40, 205, 0.1);
  position: relative;
  overflow: hidden;
}

.ot-date-card::before,
.ot-menstruation-details::before,
.ot-health-indicators::before,
.ot-measurements::before,
.ot-bmi-display::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(90deg, #FB28CD, #ff8e8e);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.ot-date-card:hover::before,
.ot-menstruation-details:hover::before,
.ot-health-indicators:hover::before,
.ot-measurements:hover::before,
.ot-bmi-display:hover::before {
  opacity: 1;
}

.ot-date-card:hover,
.ot-menstruation-details:hover,
.ot-health-indicators:hover,
.ot-measurements:hover,
.ot-bmi-display:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 15px 30px rgba(0, 0, 0, 0.1),
    inset 0 -2px 6px rgba(251, 40, 205, 0.1);
}

.ot-date-card {
  background: #ffffff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 20px;
  /* border: 2px solid #FB28CD; */
}

.ot-date-circle {
  background: linear-gradient(135deg, #FB28CD, #ff8e8e);
  border-radius: 50%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 4px solid #ffffff;
  margin: 0 auto;
}

.ot-date-circle:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.ot-date-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ot-day-text {
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 4px;
}

.ot-date {
  font-size: 2.5rem;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 4px;
}

.ot-year {
  font-size: 1.2rem;
  color: #ffffff;
}

.ot-input-field {
  margin-bottom: 0.75rem;
}

.ot-input-field label {
  display: block;
  margin-bottom: 0.25rem;
  color: #333;
}

.ot-input-field input[type="date"],
.ot-input-field input[type="number"],
.ot-input-field select,
.ot-pink-select,
.ot-purple-select {
  background-color: #f8f9fa;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  padding: 1rem;
  width: 100%;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.ot-input-field input:focus,
.ot-pink-select:focus,
.ot-purple-select:focus {
  outline: none;
  border-color: #FB28CD;
  box-shadow: 0 0 0 3px rgba(251, 40, 205, 0.1);
}

.ot-pink-select {
  background: linear-gradient(to right, #FFE6F3, #fff);
  border-color: #ffcce6;
}

.ot-purple-select {
  background: linear-gradient(to right, #F3E6FF, #fff);
  border-color: #e6ccff;
}

.ot-measurement-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ot-measurement-item label {
  font-weight: 600;
  color: #333;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.ot-measurement-item .measurement-icon {
  margin-right: 8px;
  font-size: 1.2rem;
  color: #FB28CD;
}

.ot-measurement-input {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ot-measurement-input input {
  flex: 1;
  padding: 8px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

.ot-measurement-input span {
  color: #666;
  font-size: 14px;
}

.ot-measurement-input:focus-within {
  border-color: #FB28CD;
  box-shadow: 0 0 0 3px rgba(251, 40, 205, 0.1);
}

.ot-bmi-display {
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 1rem;
  margin-top: 0.75rem;
}

.ot-bmi-result {
  text-align: center;
}

.ot-bmi-value {
  font-size: 1.2rem;
  color: #FB28CD;
  margin-bottom: 0.5rem;
}

.ot-bmi-recommendation {
  color: #666;
  margin: 0;
}

.ot-predict-button {
  background: white; 
  color: black; 
  border: 1px solid rgba(251, 40, 205, 0.1); 
  padding: 40px 10px ; 
  text-align: center; 
  text-decoration: none; 
  display: inline-block; 
  font-size: 16px; 
  margin: 60px;
  cursor: pointer; 
  border-radius: 8px; 
  transition: all 0.3s ease; 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
}

.ot-predict-button:hover {
  background-color: #FB28CD; 
  color: white; 
  transform: translateY(-5px); 
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1); 
}

.ot-predict-button:active {
  transform: scale(0.95); 
}

.ot-disclaimer {
  position: relative;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  background: #FB28CD;
  color: white;
  padding: 1rem;
  text-align: center;
  font-size: 0.9rem;
  z-index: 2;
}

.ot-credibility-link {
  color: white;
  text-decoration: underline;
  margin-left: 1rem;
  cursor: pointer;
}

@media (max-width: 1400px) {
  .ot-left-bg,
  .ot-right-bg {
    width: 250px;
  }
}

@media (max-width: 1200px) {
  .ot-left-bg,
  .ot-right-bg {
    width: 200px;
  }
}

@media (max-width: 992px) {
  .ot-background-wrapper {
    display: none;
  }
  
  .ot-container {
    margin: 1rem;
  }
}

@media (max-width: 768px) {
  .ot-left-bg,
  .ot-right-bg {
    display: none;
  }
  
  .ot-tracker-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);
  }
  
  .ot-date-card { grid-row: 1; }
  .ot-menstruation-details { grid-row: 2; }
  .ot-measurements { grid-row: 3; }
  .ot-health-indicators { grid-row: 4; }
  .ot-bmi-display { grid-row: 5; }
  .ot-predict-button { grid-row: 6; }
  
  .ot-predict-button {
    grid-column: span 1;
  }
  
  .ot-main-title {
    font-size: 2rem;
  }
  
  .ot-subtitle {
    font-size: 1rem;
  }
}

.ot-indicators-row {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  flex-wrap: wrap;
}

.ot-indicator-card {
  flex: 1;
  margin: 10px;
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 12px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.ot-indicator-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.indicator-title {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  color: #FB28CD;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.indicator-icon {
  margin-right: 10px;
  font-size: 1.5rem;
}

.ot-health-indicators {
  width: 100%;
}

.ot-measurements-wrapper {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  padding: 10px;
}

.ot-measurements-wrapper .ot-measurement-item {
  flex: 1;
}

.ot-measurements-wrapper .ot-measurement-input {
  width: 100%;
}

.ot-period-flow {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ot-period-flow .ot-input-field {
  width: 100%;
}

.ot-date-inputs {
  display: grid;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}

/* Adjust responsive behavior */
@media (max-width: 480px) {
  .ot-measurements-wrapper {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .ot-measurement-input {
    padding: 0.6rem 0.8rem;
  }
}

/* Update the grid positions */
.ot-date-card {
  grid-column: 1;
  grid-row: 1;
}

.ot-menstruation-details {
  grid-column: 2;
  grid-row: 1;
}

.ot-health-indicators {
  grid-column: 1;
  grid-row: 2;
}

/* Switched positions of BMI display and predict button */
.ot-predict-button {
  grid-column: 2;
  grid-row: 3;
  align-self: center;
}

.ot-bmi-display {
  grid-column: 1;
  grid-row: 3;
}

.ot-measurements {
  grid-column: span 2;
  grid-row: 2;
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

/* Update the mobile layout order */
@media (max-width: 768px) {
  .ot-date-card { grid-row: 1; }
  .ot-menstruation-details { grid-row: 2; }
  .ot-measurements { grid-row: 3; }
  .ot-health-indicators { grid-row: 4; }
  .ot-bmi-display { grid-row: 5; }
  .ot-predict-button { grid-row: 6; }
}

/* Add these styles for the menstruation details cards */
.ot-menstruation-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ot-input-field {
  background: white;
  border-radius: 15px;
  padding: 1.25rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(251, 40, 205, 0.1);
  transition: all 0.3s ease;
}

.ot-input-field:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
}

.ot-input-field label {
  display: block;
  color: #FB28CD;
  font-weight: 600;
  margin-bottom: 0.75rem;
  font-size: 0.95rem;
}

.ot-input-field input {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #f0f0f0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
}

.ot-input-field input:focus {
  outline: none;
  border-color: #FB28CD;
  box-shadow: 0 0 0 3px rgba(251, 40, 205, 0.1);
}

/* Style for the disabled total days input */
.ot-input-field input:disabled {
  background-color: #f8f9fa;
  border-color: #e9ecef;
  color: #FB28CD;
  font-weight: 600;
  cursor: not-allowed;
}

/* Add a small icon or indicator for the total days card */
.ot-input-field.total-days {
  position: relative;
  background: linear-gradient(to right, #FFE6F3, #fff);
}

.ot-input-field.total-days::after {
  content: '📅';
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2rem;
}

/* Adjust spacing for mobile */
@media (max-width: 768px) {
  .ot-input-field {
    padding: 1rem;
  }
  
  .ot-input-field label {
    font-size: 0.9rem;
  }
}

/* Menstruation detail cards specific styles */
.menstrual-input-card {
  background: white;
  border-radius: 15px;
  padding: 1.25rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(251, 40, 205, 0.1);
  transition: all 0.3s ease;
  position: relative;
}

.menstrual-input-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
}

.menstrual-input-card label {
  display: block;
  color: #FB28CD;
  font-weight: 600;
  margin-bottom: 0.75rem;
  font-size: 0.95rem;
}

.menstrual-input-card input {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #f0f0f0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
}

.menstrual-input-card input:focus {
  outline: none;
  border-color: #FB28CD;
  box-shadow: 0 0 0 3px rgba(251, 40, 205, 0.1);
}

/* First period card specific styles */
.menstrual-input-card.first-period {
  background: linear-gradient(to right, #FFE6F3, #fff);
  border-left: 4px solid #FB28CD;
}

.menstrual-input-card.first-period::after {
  content: '📅';
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2rem;
}

/* Last period card specific styles */
.menstrual-input-card.last-period {
  background: linear-gradient(to right, #F3E6FF, #fff);
  border-left: 4px solid #9B28FB;
}

.menstrual-input-card.last-period::after {
  content: '📅';
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2rem;
}

/* Period duration card specific styles */
.menstrual-input-card.period-duration {
  background: linear-gradient(to right, #E6FFF3, #fff);
  border-left: 4px solid #28FB9B;
}

.menstrual-input-card.period-duration::after {
  content: '📊';
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2rem;
}

/* Disabled input specific styles */
.menstrual-input-card.period-duration input:disabled {
  background-color: #f8f9fa;
  border-color: #e9ecef;
  color: #28FB9B;
  font-weight: 600;
  cursor: not-allowed;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .menstrual-input-card {
    padding: 1rem;
  }
  
  .menstrual-input-card label {
    font-size: 0.9rem;
  }
}

.ot-no-prediction {
  font-size: 1.2rem;
  color: #ffffff;
  text-align: center;
}

.ot-date-display {
  padding: 8px 12px;
  background-color: #f8f9fa;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #495057;
  min-width: 150px;
  text-align: center;
  transition: opacity 0.3s ease; /* Smooth transition for opacity */
}

.ot-date-display.animate {
  animation: fadeIn 0.5s ease forwards; /* Apply fade-in animation */
}

.ot-range-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ot-range-item span {
  font-weight: 500;
  color: #6c757d;
}

.loading-spinner {
    border: 8px solid rgba(251, 40, 205, 0.1); /* Light border */
    border-top: 8px solid #FB28CD; /* Color of the spinner */
    border-radius: 50%;
    width: 60px; /* Size of the spinner */
    height: 60px; /* Size of the spinner */
    animation: spin 1s linear infinite; /* Spin animation */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto; /* Center the spinner */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Add this animation to your CSS file */
@keyframes circleAnimation {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

.ot-date-circle.animate {
  animation: circleAnimation 1.5s infinite; /* Adjust duration and timing as needed */
}

.ot-date-range {
  display: flex;
  justify-content: space-between; /* Space items evenly */
  background-color: #f8f9fa; /* Light background for contrast */
  border-radius: 10px; /* Rounded corners */
  padding: 15px; /* Padding for spacing */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-top: 20px; /* Space above the date range */
}

.ot-range-item {
  flex: 1; /* Allow items to grow equally */
  text-align: center; /* Center text */
  padding: 10px; /* Padding for each item */
  transition: background-color 0.3s ease; /* Smooth background transition */
}

.ot-range-item:hover {
  background-color: rgba(251, 40, 205, 0.2); /* Light hover effect */
  border-radius: 8px; /* Rounded corners on hover */
}

.ot-range-label {
  font-weight: bold; /* Bold label for emphasis */
  color: #FB28CD; /* Consistent color with the theme */
}

.ot-date-display {
  font-size: 1.2rem; /* Larger font size for dates */
  color: #333; /* Darker color for better readability */
}

.ot-pending-text {
  color: #888; /* Color for pending text */
  font-style: italic; /* Italic style for pending text */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px); /* Start slightly lower */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}

/* Add this to make the layout responsive */
@media (max-width: 768px) {
    .ot-container {
        flex-direction: column; /* Stack elements vertically on smaller screens */
        padding: 20px; /* Add some padding */
    }

    .ot-background-wrapper {
        flex-direction: column; /* Stack background images vertically */
    }

    .ot-left-bg, .ot-right-bg {
        width: 100%; /* Full width for images */
        height: auto; /* Maintain aspect ratio */
    }

    .ot-tracker-grid {
        display: flex;
        flex-direction: column; /* Stack cards vertically */
    }

    .ot-date-card, .ot-menstruation-details, .ot-health-indicators, .ot-measurements {
        width: 100%; /* Full width for cards */
        margin-bottom: 20px; /* Space between cards */
    }

    .ot-indicators-row {
        flex-direction: column; /* Stack indicators vertically */
        align-items: center; /* Center align items */
    }

    .ot-indicator-card {
        width: 100%; /* Full width for indicator cards */
        margin-bottom: 15px; /* Space between indicator cards */
    }

    .ot-bmi-display {
        text-align: center; /* Center text for better readability */
    }

    .ot-predict-button {
        width: 100%; /* Full width for button */
        padding: 15px; /* Increase padding for touch targets */
    }
}

/* Add this to make the ot-measurements-wrapper responsive */
@media (max-width: 768px) {
    .ot-measurements-wrapper {
        flex-direction: column; /* Stack items vertically on smaller screens */
        align-items: stretch; /* Ensure items take full width */
    }

    .ot-measurement-item {
        margin-bottom: 15px; /* Space between measurement items */
    }
}

.ot-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.ot-unit {
  margin-left: 5px;
  font-weight: bold;
  color: #555;
}